import React, { useState, useEffect } from "react";
import {
    Text,
    HStack,
    VStack,
    Box,
    Center,
    Divider,
    Spinner,
    useToast,
    Pressable,
    FlatList,
    ScrollView,
    Menu,
    Card,
    Heading,
    Flex,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { aw, ah, aw1, ah1 } from "../../utils";
import ToastAlert from "../../components/ToastAlert";
import { useForm } from "react-hook-form";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSearch from "../../components/LeadsWatchSearch";
import LeadsWatchSearchIcon from "../../components/svg/LeadsWatchSearchIcon";
import { teamUsers } from "../../store/actions/userteams";
import LeadsWatchMenu from "../../components/LeadsWatchMenu";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchInput from "../../components/LeadsWatchInput";
import LeadsWatchButton from "../../components/LeadsWatchButton";

const TeamUsers = () => {
    const time = localStorage.getItem("time");
    const navigate = useNavigate();
    const toast = useToast();
    const [edit, setEdit] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [autoRefresh, setAutoRefresh] = useState(true);
    const [currentPage, setCurrentPage] = useState("1");
    const [open, setOpen] = useState(false);
    const [update, setupdate] = useState(false);
    const [selectCount, setSeletCount] = useState("10");
    const [isLoading, setIsLoading] = useState(true);
    // const teams = useSelector((state) => state.teams.allTeams);
    const teamusers = useSelector((state) => state.teamUsers.teamUsers);
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const { id } = useParams();
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        getValues,
    } = useForm({
        mode: "all",
        defaultValues: {
            select: "10",

        },
    });
    const handleChangeSelect = (value) => {
        setSeletCount(value);
        setCurrentPage(1);
    };
    const {
        control: control1,
        handleSubmit: handleSubmit1,
        formState: { errors: errors1 },
        reset: reset1,
        getValues: getValues1,
    } = useForm({
        mode: "all",
    });
    const handleUserClick = (user) => {
        setOpen(true);
        console.log("User clicked:", user.firstname);
    };
    const fetchDeatils = async () => {
        // setIsLoading(true);
        try {
            await dispatch(teamUsers(id));
        } catch (err) {
            toast.show({
                placement: "top",
                render: () => {
                    return (
                        <ToastAlert
                            desc={err.message}
                            status="error"
                            title="error"
                        />
                    );
                },
            });
        }
        setIsLoading(false);
    };
    const handleSearch = (data) => {
        console.log(data);
        setSearch(data.search);
        setCurrentPage(1);
    };
    useEffect(() => {
        fetchDeatils();
    }, []);
    console.log(teamusers, "hii")
    useEffect(() => {
        reset({ firstname: teamusers?.firstname, lastname: teamusers?.lastname, email: teamusers?.email, role: teamusers?.role, company: teamusers?.company, device_id: teamusers?.device_id, address: { address1: teamusers?.address?.address1, address2: teamusers?.address?.address2, zipcode: teamusers?.address?.zipcode, state: teamusers?.address?.state } })
    }, [teamusers])
    return (
        <>
            <VStack width={aw(1100)}>
                <VStack ml={aw1(55)} space={ah1(18)} width={aw1(1100)}>
                    <HStack
                        justifyContent="space-between"
                        alignItems={"center"}
                        width={aw1(1100)}
                    >
                        <VStack justifyContent={"center"} >
                            <HStack justifyContent={"space-between"}
                                width={aw(1100)}
                                alignItems={"center"}>
                                <VStack>
                                    <HStack space={aw(8)} alignItems={"baseline"}>
                                        <Text fontSize={aw(26)}
                                            color={"#121233"}
                                            fontFamily="RobotoMedium">TeamUser Details</Text>

                                    </HStack>
                                    <Text lineHeight={ah1(34)} fontSize={ah1(14)} fontFamily={"RobotoRegular"}
                                        color={"#919191"}
                                        fontWeight={400}> the uers details will be shown here</Text>
                                </VStack>
                                <LeadsWatchButton
                                    color="#FAFBFD"
                                    isDisabled={disabled}
                                    onPress={() => {
                                        navigate("/team")
                                    }}
                                    style={{
                                        width: aw(100),
                                        height: ah(40),
                                        borderRadius: "10px",
                                    }}
                                    label={"Back"}

                                    bg="#0000FF"
                                    borderColor="#FAFBFD"
                                />
                            </HStack>
                        </VStack>

                    </HStack>
                    <Divider bg="#E6E7E9" />
                    {/* <ScrollView maxH={ah(550)}> */}
                    <Box top={ah(10)} borderColor={"gray.200"} shadow={"5"} borderWidth={aw(1)} width={aw(1000)} borderRadius={aw(10)}>
                        {teamusers ?
                            <Box >
                                <Box bgColor={"#F9FAFB"}  >
                                    <HStack alignItems={"center"} justifyContent={"space-between"} px={aw(10)}>
                                        <Heading padding={aw(10)}>
                                            {teamusers.firstname} {teamusers.lastname}
                                        </Heading>
                                        <LeadsWatchButton
                                            color="#FAFBFD"
                                            isDisabled={disabled}
                                            onPress={() => {
                                                setEdit(!edit)
                                            }}
                                            style={{
                                                width: aw(100),
                                                height: ah(40),
                                                borderRadius: "10px",
                                            }}
                                            // { !update ? label = "Edit" : label = "Update"}
                                            label={!edit ? "Edit" : "Update"}

                                            bg="#0000FF"
                                            borderColor="#FAFBFD"
                                        />
                                    </HStack>
                                </Box>
                                <Divider bg="#E6E7E9" />
                                <VStack justifyContent={"normal"} padding={aw(10)} space={aw(7)} bgColor={"FFFFFF"}>
                                    <HStack justifyContent={"space-between"} width={"100%"}>
                                        <Text fontSize={ah1(14)} fontFamily={"Poppins_medium"} >Firstname : </Text>
                                        <LeadsWatchInput control={control} name="firstname" isDisabled={!edit} style={{ width: aw(200) }} />
                                        {/* </HStack>
                                    <HStack justifyContent={"space-between"}> */}
                                        <Text fontSize={ah1(14)} fontFamily={"Poppins_medium"} fontWeight={"bold"}>Lastname : </Text>
                                        <LeadsWatchInput control={control} name="lastname" isDisabled={!edit} style={{ width: aw(200) }} />
                                    </HStack>
                                    <HStack justifyContent={"space-between"}>
                                        <Text fontSize={ah1(14)} fontFamily={"Poppins_medium"} fontWeight={"bold"}>Email : </Text>
                                        <LeadsWatchInput control={control} name="email" isDisabled={!edit} style={{ width: aw(200) }} />
                                        <Text fontSize={ah1(14)} fontFamily={"Poppins_medium"} fontWeight={"bold"}> Role : </Text>
                                        <LeadsWatchInput control={control} name="role" isDisabled={!edit} style={{ width: aw(200) }} />
                                    </HStack>
                                    <HStack justifyContent={"space-between"}>
                                        <Text fontSize={ah1(14)} fontFamily={"Poppins_medium"} fontWeight={"bold"}>Company : </Text>
                                        <LeadsWatchInput control={control} name="company" isDisabled={!edit} style={{ width: aw(200) }} />
                                        {/* </HStack>
                                    <HStack justifyContent={"space-between"}> */}
                                        <Text fontSize={ah1(14)} fontFamily={"Poppins_medium"} fontWeight={"bold"}>Device_id : </Text>
                                        <LeadsWatchInput control={control} name="device_id" isDisabled={!edit} style={{ width: aw(200) }} />
                                    </HStack>
                                    <HStack justifyContent={"space-between"}>
                                        <Text fontSize={ah1(14)} fontFamily={"Poppins_medium"} fontWeight={"bold"}>Address1 : </Text>
                                        <LeadsWatchInput control={control} name="address.address1" isDisabled={!edit} style={{ width: aw(200) }} />
                                        {/* </HStack>
                                    <HStack justifyContent={"space-between"}> */}
                                        <Text fontSize={ah1(14)} fontFamily={"Poppins_medium"} fontWeight={"bold"}>Address2 : </Text>
                                        <LeadsWatchInput control={control} name="address.address2" isDisabled={!edit} style={{ width: aw(200) }} />
                                    </HStack>
                                    <HStack justifyContent={"space-between"}>
                                        <Text fontSize={ah1(14)} fontFamily={"Poppins_medium"} fontWeight={"bold"}>Zipcode : </Text>
                                        <LeadsWatchInput control={control} name="address.zipcode" isDisabled={!edit} style={{ width: aw(200) }} />
                                        {/* </HStack>
                                    <HStack justifyContent={"space-between"}> */}
                                        <Text fontSize={ah1(14)} fontFamily={"Poppins_medium"} fontWeight={"bold"}>State : </Text>
                                        <LeadsWatchInput control={control} name="address.state" isDisabled={!edit} style={{ width: aw(200) }} />
                                    </HStack>
                                </VStack>
                            </Box> :
                            <Text>Loading</Text>}

                    </Box>
                    {/* </ScrollView> */}
                </VStack>
            </VStack >
        </>
    )

}
export default TeamUsers;