import * as events from "../Events";
const token = localStorage.getItem("auth");
export const allTeams = (page, limit, search) => {
    return async (dispatch) => {
        try {
            const response = await fetch(
                `${events.API_URL}/admin/getteams?page=${page}&limit=${limit}`,

                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        search: search,
                    }),
                }
            );
            if (!response.ok) {
                const resError = await response.json();
                console.log("Response Error", resError);

                if (resError && resError.error) {
                    throw new Error(resError.error);
                }
            } else {
                const resData = await response.json();
                console.log(resData.data);

                const allTeams = resData.data;
                dispatch({ type: events.GET_ALL_TEAMS, data: allTeams });

                return allTeams ? true : false;
            }
        } catch (err) {
            console.log(err);
            throw new Error(err.message);
        }
    };
};
export const allTeamUsers = (id) => {
    console.log("allTeamUsers id", id);
    return async (dispatch) => {
        try {
            const response = await fetch(
                `${events.API_URL}/admin/getteamusers/${id}`,

                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!response.ok) {
                const resError = await response.json();
                console.log("Response Error", resError);

                if (resError && resError.error) {
                    throw new Error(resError.error);
                }
            } else {
                const resData = await response.json();
                console.log(resData.data);

                const allTeamUsers = resData.data;
                dispatch({ type: events.GET_ALL_TEAMUSERS, data: allTeamUsers });

                return allTeamUsers ? true : false;
            }
        } catch (err) {
            console.log(err);
            throw new Error(err.message);
        }
    };
};
export const allBuyerContacts = (id) => {
    console.log("allBuyerContacts id", id);
    return async (dispatch) => {
        try {
            const response = await fetch(
                `${events.API_URL}/admin/team/dashboard/${id}`,

                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!response.ok) {
                const resError = await response.json();
                console.log("Response Error", resError);

                if (resError && resError.error) {
                    throw new Error(resError.error);
                }
            } else {
                const resData = await response.json();
                console.log(resData.data, "buyercontacts");

                const allBuyers = resData.data;
                dispatch({ type: events.GET_ALL_TEAMBUYERS, data: allBuyers });

                return allBuyers ? true : false;
            }
        } catch (err) {
            console.log(err);
            throw new Error(err.message);
        }
    };
};
export const allPropertyteam = (_id, limit, page, search) => {
    return async (dispatch) => {
        try {
            const response = await fetch(
                `${events.API_URL}/admin/team/properties/${_id}?limit=${limit}&page=${page}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        search: search,
                    }),
                }
            );
            if (!response.ok) {
                console.log("step 1")
                const resError = await response.json();
                console.log("Response Error", resError);

                if (resError && resError.error) {
                    throw new Error(resError.error);
                }
            } else {
                const resData = await response.json();
                console.log("step 2", resData)
                const allPropertyteam = resData.data;
                dispatch({ type: events.GET_ALL_PROPERTIES, data: allPropertyteam });

                return allPropertyteam ? true : false;
            }
        } catch (err) {
            console.log(err);
            throw new Error(err.message);
        }
    };
};
export const allPropDetails = (_id) => {
    return async (dispatch) => {
        try {
            const response = await fetch(
                `${events.API_URL}/admin/team/prop/${_id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!response.ok) {
                console.log("step 1")
                const resError = await response.json();
                console.log("Response Error", resError);

                if (resError && resError.error) {
                    throw new Error(resError.error);
                }
            } else {

                const resData = await response.json();
                console.log("step 2", resData)
                const allPropDetails = resData.data;
                dispatch({ type: events.GET_ALL_PROPDETAILS, data: allPropDetails });

                return allPropDetails ? true : false;
            }
        } catch (err) {
            console.log(err);
            throw new Error(err.message);
        }
    };
};

export const allSignatures = (id, limit, page, search) => {
    console.log("allSignatures id", id);
    return async (dispatch) => {
        try {
            const response = await fetch(
                `${events.API_URL}/admin/team/signatures/${id}?limit=${limit}&page=${page}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        search: search,
                    }),
                }
            );

            if (!response.ok) {
                const resError = await response.json();
                console.log("Response Error", resError);

                if (resError && resError.error) {
                    throw new Error(resError.error);
                }
            } else {
                const resData = await response.json();
                console.log(resData, "signatures"); // Log the response data

                // Dispatch the response data directly
                dispatch({ type: events.GET_ALL_TEAMSIGNATURES, data: resData });

                return resData 
            }
        } catch (err) {
            console.log(err);
            throw new Error(err.message);
        }
    };
};

export const allTeamplates = (id, limit, page, search) => {
    console.log("alltemplates id", id);
    return async (dispatch) => {
        try {
            const response = await fetch(
                `${events.API_URL}/admin/team/templates/${id}?limit=${limit}&page=${page}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        search: search,
                    }),
                }
            );

            if (!response.ok) {
                const resError = await response.json();
                console.log("Response Error", resError);

                if (resError && resError.error) {
                    throw new Error(resError.error);
                }
            } else {
                const resData = await response.json();
                console.log(resData, "templates"); // Log the response data

                // Dispatch the response data directly
                dispatch({ type: events.GET_ALL_TEAMTEMPLATES, data: resData });

                return resData 
            }
        } catch (err) {
            console.log(err);
            throw new Error(err.message);
        }
    };
};

export const allCampaigns = (id, limit, page, search) => {
    console.log("allcampaigns id", id);
    return async (dispatch) => {
        try {
            const response = await fetch(
                `${events.API_URL}/admin/team/campaigns/${id}?limit=${limit}&page=${page}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        search: search,
                    }),
                }
            );

            if (!response.ok) {
                const resError = await response.json();
                console.log("Response Error", resError);

                if (resError && resError.error) {
                    throw new Error(resError.error);
                }
            } else {
                const resData = await response.json();
                console.log(resData, "campaigns"); // Log the response data

                // Dispatch the response data directly
                dispatch({ type: events.GET_ALL_TEAMCAMPAIGNS, data: resData });

                return resData 
            }
        } catch (err) {
            console.log(err);
            throw new Error(err.message);
        }
    };
};

export const allLists = (id, limit, page, search) => {
    console.log("allLists id", id);
    return async (dispatch) => {
        try {
            const response = await fetch(
                `${events.API_URL}/admin/team/lists/${id}?limit=${limit}&page=${page}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        search: search,
                    }),
                }
            );

            if (!response.ok) {
                const resError = await response.json();
                console.log("Response Error", resError);

                if (resError && resError.error) {
                    throw new Error(resError.error);
                }
            } else {
                const resData = await response.json();
                console.log(resData, "lists"); // Log the response data

                // Dispatch the response data directly
                dispatch({ type: events.GET_ALL_TEAMLISTS, data: resData });

                return resData 
            }
        } catch (err) {
            console.log(err);
            throw new Error(err.message);
        }
    };
};

export const allListsDetails = (id, limit, page, search) => {
    console.log("allListsDetails id", id);
    return async (dispatch) => {
        try {
            const response = await fetch(
                `${events.API_URL}/admin/team/lists/details/${id}?limit=${limit}&page=${page}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        search: search,
                    }),
                }
            );

            if (!response.ok) {
                const resError = await response.json();
                console.log("Response Error", resError);

                if (resError && resError.error) {
                    throw new Error(resError.error);
                }
            } else {
                const resData = await response.json();
                console.log(resData, "listsDetails"); // Log the response data

                // Dispatch the response data directly
                dispatch({ type: events.GET_ALL_TEAMLISTSDETAILS, data: resData });

                return resData 
            }
        } catch (err) {
            console.log(err);
            throw new Error(err.message);
        }
    };
};

export const allListsProperties = (id) => {
    console.log("allListsProperties id", id);
    return async (dispatch) => {
        try {
            const response = await fetch(
                `${events.API_URL}/admin/team/lists/details/prop/${id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                const resError = await response.json();
                console.log("Response Error", resError);

                if (resError && resError.error) {
                    throw new Error(resError.error);
                }
            } else {
                const resData = await response.json();
                console.log(resData, "listsProperties"); // Log the response data

                // Dispatch the response data directly
                dispatch({ type: events.GET_ALL_TEAMLISTSPROPERTIES, data: resData });

                return resData 
            }
        } catch (err) {
            console.log(err);
            throw new Error(err.message);
        }
    };
};



