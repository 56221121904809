import * as events from "../Events";
const token = localStorage.getItem("auth");
export const teamUsers = (id) => {
    console.log("teamUsers id", id);
    return async (dispatch) => {
        try {
            const response = await fetch(
                `${events.API_URL}/admin/getusers/${id}`,

                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!response.ok) {
                const resError = await response.json();
                console.log("Response Error", resError);

                if (resError && resError.error) {
                    throw new Error(resError.error);
                }
            } else {
                const resData = await response.json();
                console.log(resData.data);

                const teamUsers = resData.data;
                dispatch({ type: events.GET_TEAMUSERS, data: teamUsers });

                return teamUsers ? true : false;
            }
        } catch (err) {
            console.log(err);
            throw new Error(err.message);
        }
    };
};
