import React, { useState, useEffect } from "react";
import {
    Box,
    HStack,
    VStack,
    useToast,
    Text,
    Center,
    Flex,
    ScrollView,
    Badge,
    Spinner,
    Divider,
    Pressable,
    Card,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { allListsDetails } from "../../store/actions/teams";
import ToastAlert from "../../components/ToastAlert";

import { aw, ah, aw1, ah1 } from "../../utils";


// Define the chunkArray function
function chunkArray(array, chunkSize) {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
}

const ListDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectCount, setSelectCount] = useState("10");
    const [search, setSearch] = useState("");
    const [listdetailsData, setListDetailsData] = useState([]);
    const toast = useToast();
    const navigate = useNavigate();

    const handleCardPress = (_id) => {
        // Navigate to another page with the _id
           navigate(`${_id}`);
      };

    const fetchListsDetailsData = async () => {
        try {
            const response = await dispatch(
                allListsDetails(id, selectCount, currentPage, search)
            );
            console.log("lists Details Response:", response);
            if (response && response.data && response.data.list) {
                console.log("lists Details  Data:", response.data.list);
                setListDetailsData(response.data.list);
            } else {
                console.log("No lists Details  data found.");
                setListDetailsData([]);
            }
        } catch (err) {
            console.error("Error fetching lists Details  data:", err);
            toast.show({
                placement: "top",
                render: () => {
                    return (
                        <ToastAlert desc={err.message} status="error" title="error" />
                    );
                },
            });
        }
    };

    useEffect(() => {
        fetchListsDetailsData();
    }, [id, currentPage, selectCount, search]);

    return (
        <VStack height={ah1(600)} width={aw(1100)} mt={ah(10)} space={ah(20)}>
          
                <Text>List Details</Text>
                {listdetailsData.length === 0 ? (
                    <Text>Properties Not added</Text>
                ) : (
                    <>

                        {chunkArray(listdetailsData, 4).map((row, rowIndex) => (
                            <HStack key={rowIndex} space={2} my={2}>
                                {row.map((list) => (
                                    <Pressable
                                    key={list.id}
                                    onPress={() => {
                                        console.log("id", list._id);
                                      handleCardPress(list._id);
                                     }}
                                    >
                                        <Card
                                            key={list.id}
                                            p={2}
                                            borderWidth={1}
                                            width={aw(250)}
                                            height={ah(150)}
                                            borderColor="gray.200"
                                            borderRadius="md"
                                        >
                                            <VStack>
                                                <HStack>
                                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                        Name :
                                                    </Text>
                                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                        {list.listname}
                                                    </Text>
                                                </HStack>
                                                <HStack>
                                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                        Property ID  : 
                                                    </Text>
                                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                        #{list.tid}
                                                    </Text>
                                                </HStack>
                                                {/* <HStack>
                                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                        property Status: 
                                                    </Text>
                                                    {list.length === 0 ? (
                                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                            Status not set
                                                        </Text>
                                                    ) : (
                                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                        {list.status.name}
                                                    </Text>
                                                    )}
                                                </HStack> */}
                                                <HStack>
                                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                        Campaign  : 
                                                    </Text>
                                                    {list.campaign.length === 0 ? ( 
                                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                             Campaign not set
                                                        </Text>
                                                    ) : (
                                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                            {list.campaign.name}
                                                        </Text>
                                                    )}
                                                </HStack>
                                            </VStack>
                                        </Card>
                                    </Pressable>
                                ))}
                            </HStack>
                        ))}
                    </>
                )}
        </VStack>
    );
};

export default ListDetails;
