import React, { useState, useEffect } from "react";
import {
    Box,
    HStack,
    VStack,
    useToast,
    Text,
    Center,
    Flex,
    ScrollView,
    Badge,
    Spinner,
    Divider,
    Pressable,
    Card,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { aw, ah, aw1, ah1 } from "../../utils";
import { allTeamUsers } from "../../store/actions/teams";
import { allLists } from "../../store/actions/teams";
import ToastAlert from "../../components/ToastAlert";

// Define the chunkArray function
function chunkArray(array, chunkSize) {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
}

const ListsTeam = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const toast = useToast();
    const navigate = useNavigate();
    const listDetails = useSelector((state) => state.allLists);
    const allTeamuser = useSelector(
        (state) => state.allteamusers.allTeamUsers
    );
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectCount, setSelectCount] = useState("10");
    const [search, setSearch] = useState("");
    const [selectedUser, setSelectedUser] = useState(null); // Store the selected user for fetching signatures
    const [listData, setListData] = useState([]); // Store the fetched signature data
const [ownerListData, setOwnerListData] = useState([]); // Store the fetched signature data for the owner
  const [userListData, setUserListData] = useState([]); // Store the fetched signature data for the selected user


  const handleCardPress = (_id) => {
    // Navigate to another page with the _id
       navigate(`lists/details/${_id}`);
  };

 
 
  const fetchDetails = async () => {
        try {
            await dispatch(allTeamUsers(id, selectCount, currentPage, search));
        } catch (err) {
            toast.show({
                placement: "top",
                render: () => {
                    return (
                        <ToastAlert desc={err.message} status="error" title="error" />
                    );
                },
            });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchDetails();
    }, [id, currentPage, selectCount, search]);

    const fetchOwnerListData = async () => {
        try {
          const response = await dispatch(
            allLists(id, selectCount, currentPage, search)
          );
          console.log("Owner API Response:", response);
          if (response && response.data && response.data.list) {
            console.log("Owner List Data:", response.data.list);
            setOwnerListData(response.data.list);
          } else {
            console.log("No owner list data found.");
            setOwnerListData([]);
          }
        } catch (err) {
          console.error("Error fetching owner list data:", err);
          toast.show({
            placement: "top",
            render: () => {
              return (
                <ToastAlert desc={err.message} status="error" title="error" />
              );
            },
          });
        }
      };
    
      const fetchUserListData = async (userIdToFetch) => {
        try {
          const response = await dispatch(
            allLists(userIdToFetch, selectCount, currentPage, search)
          );
          console.log("User API Response:", response);
          if (response && response.data && response.data.list) {
            console.log("User List Data:", response.data.list);
            setUserListData(response.data.list);
          } else {
            console.log("No user list data found.");
            setUserListData([]);
          }
        } catch (err) {
          console.error("Error fetching user list data:", err);
          toast.show({
            placement: "top",
            render: () => {
              return (
                <ToastAlert desc={err.message} status="error" title="error" />
              );
            },
          });
        }
      };
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            if (selectedUser) {
              await fetchUserListData(selectedUser._id);
              await fetchOwnerListData(); // Fetch owner data as well
            } else {
              await fetchOwnerListData();
            }
          } catch (err) {
            console.error("Error fetching data:", err);
          }
          setIsLoading(false);
        };
    
        fetchData();
      }, [id, selectCount, currentPage, search, selectedUser]);
    

    const data = allTeamuser || [];

    const handleUserClick = async (user) => {
        // Set the selected user
        setSelectedUser(user);
    };



    return (
        <VStack height={ah1(600)} width={aw(1100)} mt={ah(10)} space={ah(20)}>
            <VStack
                // Card 1 - Display details based on URL params

                width={aw(1100)}
                p={4}
            >
                <Text fontSize={aw(14)} fontFamily="RobotoMedium">
                    Owner Lists:
                </Text>
                {/* Display signature details for the selected user or based on URL params */}
                <ScrollView>
                    {ownerListData.length === 0 ? (
                        <Text>No lists found.</Text>
                    ) : (
                        <>
                            {chunkArray(ownerListData, 4).map((row, rowIndex) => (
                                <HStack key={rowIndex} space={2} my={2}>
                                    {row.map((list) => (
                                         <Pressable
                                         key={list.id}
                                         onPress={() => {
                                           handleCardPress(list._id);
                                          }}
                                         >
                                        <Card
                                            key={list.id}
                                            p={2}
                                            borderWidth={1}
                                            width={aw(250)}
                                            height={ah(150)}
                                            borderColor="gray.200"
                                            borderRadius="md"
                                        >
                                            <VStack>
                                                <HStack>
                                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                        Name :
                                                    </Text>
                                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                        {list.name}
                                                    </Text>
                                                </HStack>
                                                <HStack>
                                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                        Order :
                                                    </Text>
                                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                        {list.ord}
                                                    </Text>
                                                </HStack>
                                                <HStack>
                                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                        Created By :
                                                    </Text>
                                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                        {list.createdUser.firstname + " " + list.createdUser.lastname}
                                                    </Text>
                                                </HStack>
                                            </VStack>
                                        </Card>
                                        </Pressable>
                                    ))}
                                </HStack>
                            ))}
                        </>
                    )}
                </ScrollView>
            </VStack>

            <VStack
                width="100%"
                p={4}
            >
                <Text fontSize={aw(14)} fontFamily="RobotoMedium">
                    Users:
                </Text>
                <Divider width="100%" /> 
                <HStack width={aw(1100)} space={2} justifyContent="space-around" >
                    {data.map((user) => (
                        <HStack
                            space={2}
                        >
                            <Pressable
                                key={user.id}
                                onPress={() => handleUserClick(user)}

                            >

                                <Text>
                                    {user.firstname} {user.lastname}
                                </Text>
                            </Pressable>
                        </HStack>
                    ))}
                </HStack>
                <Divider width="100%" />
            </VStack>
            {selectedUser && (
                <VStack
                    // Card 3 - Display signatures for the selected user
                 
                    width="100%"
                    p={4}
                >
                    <Text fontSize={aw(14)} fontFamily="RobotoMedium">
                        lists for {selectedUser.firstname} {selectedUser.lastname}:
                    </Text>
                    {/* Display list details for the selected user */}
                    <ScrollView>
                        {userListData.length === 0 ? (
                            <Text>No lists found for {selectedUser.firstname} {selectedUser.lastname}.</Text>
                        ) : (
                            <>
                                {chunkArray(userListData, 4).map((row, rowIndex) => (
                                    <HStack key={rowIndex} space={2} my={2}>
                                        {row.map((list) => (
                                             <Pressable
                                             key={list.id}
                                             onPress={() => handleCardPress(list._id)} // Pass the _id when the card is pressed
                                           >
                                            <Card
                                                key={list.id}
                                                p={2}
                                                borderWidth={1}
                                                borderColor="gray.200"
                                                borderRadius="md"
                                                width={aw(250)}
                                                height={ah(150)}
                                            >
                                                <VStack>
                                                    <HStack>
                                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                            Name :
                                                        </Text>
                                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                            {list.name}
                                                        </Text>
                                                    </HStack>
                                                    <HStack>
                                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                            order :
                                                        </Text>
                                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                            {list.ord}
                                                        </Text>
                                                    </HStack>
                                                      <HStack>
                                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                        Created By :
                                                    </Text>
                                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                                                        {list.createdUser.firstname + " " + list.createdUser.lastname}
                                                    </Text>
                                                </HStack>
                                                </VStack>
                                            </Card>
                                            </Pressable>
                                        ))}
                                    </HStack>
                                ))}
                            </>
                        )}
                    </ScrollView>
                </VStack>

            )}
        </VStack>
    );
};

export default ListsTeam;
