import React, { useState, useEffect } from "react";
import {
    Text,
    HStack,
    VStack,
    Box,
    Center,
    Divider,
    Spinner,
    useToast,
    Pressable,
    FlatList,
    ScrollView,
    Menu,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { aw, ah, aw1, ah1 } from "../../utils";
import ToastAlert from "../../components/ToastAlert";
import { useForm } from "react-hook-form";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSearch from "../../components/LeadsWatchSearch";
import LeadsWatchSearchIcon from "../../components/svg/LeadsWatchSearchIcon";
import { allTeams } from "../../store/actions/teams";
import LeadsWatchMenu from "../../components/LeadsWatchMenu";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchButton from "../../components/LeadsWatchButton";

const Teams = () => {
    const time = localStorage.getItem("time");
    const toast = useToast();
    const [currentPage, setCurrentPage] = useState("1");
    const [open, setOpen] = useState(false);
    const [selectCount, setSeletCount] = useState("10");
    const [isLoading, setIsLoading] = useState(true);
    const teams = useSelector((state) => state.teams.allTeams);
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        getValues,
    } = useForm({
        mode: "all",
        defaultValues: {
            select: "10",
            refreshTime: `${time}(s)`,
        },
    });
    const handleChangeSelect = (value) => {
        setSeletCount(value);
        setCurrentPage(1);
    };
    const {
        control: control1,
        handleSubmit: handleSubmit1,
        formState: { errors: errors1 },
        reset: reset1,
        getValues: getValues1,
    } = useForm({
        mode: "all",
    });
    const handleUserClick = (user) => {
        setOpen(true);
        navigate(`/teams/userDetails/${user._id}`);
    };
    const handleTeamDashBoard = (item) => {
        navigate(`/teams/dashboard/${item._id}`);
    };
    const fetchDeatils = async () => {
        // setIsLoading(true);
        try {
            await dispatch(allTeams(currentPage, selectCount, search));
        } catch (err) {
            toast.show({
                placement: "top",
                render: () => {
                    return (
                        <ToastAlert
                            desc={err.message}
                            // handleClose={() => toast.close()}
                            status="error"
                            title="error"
                        />
                    );
                },
            });
        }
        setIsLoading(false);
    };
    const handleSearch = (data) => {
        console.log(data, "search");
        setSearch(data.search);
        setCurrentPage(1);
    };
    useEffect(() => {
        fetchDeatils();
    }, [search, currentPage, selectCount]);
    console.log(teams, "teams")
    return (
        <>
            <VStack width={aw(1100)}>
                <VStack ml={aw1(55)} space={ah1(18)} width={aw1(1100)}>
                    <HStack
                        justifyContent="space-between"
                        alignItems={"center"}
                        width={aw1(1100)}
                    >
                        <VStack justifyContent={"center"} >
                            <HStack justifyContent={"space-between"}
                                width={aw(1100)}
                                alignItems={"center"}>
                                <VStack>
                                    <HStack space={aw(8)} alignItems={"baseline"}>
                                        <Text fontSize={aw(26)}
                                            color={"#121233"}
                                            fontFamily="RobotoMedium">Teams</Text>
                                        <Text
                                            fontSize={aw(18)}
                                            color={"#919191"}
                                            fontFamily="RobotoMedium"
                                        >
                                            {teams && teams.total_count}
                                        </Text>
                                    </HStack>
                                    <Text lineHeight={ah1(34)} fontSize={ah1(14)} fontFamily={"RobotoRegular"}
                                        color={"#919191"}
                                        fontWeight={400}>All the owners will be shown here</Text>
                                </VStack>
                                <LeadsWatchSearch
                                    control={control}
                                    name="search"
                                    inputRightElement={
                                        <Pressable
                                            w={aw1(30)}
                                            onPress={handleSubmit1(handleSearch)}
                                        >
                                            <LeadsWatchSearchIcon />
                                        </Pressable>
                                    }
                                    onKeyPress={(e) => { if (e.key === 'Enter') { handleSubmit1(handleSearch({ search: getValues('search') })) } }}
                                />
                            </HStack>
                        </VStack>
                    </HStack>
                    <Divider bg="#E6E7E9" />
                    <VStack height={ah(35)}>
                        <HStack justifyContent={"space-between"}>
                            <HStack height={ah(32)} alignItems="center">
                                <Text
                                    fontSize={aw(14)}
                                    fontFamily="RobotoRegular"
                                    color="#212121"
                                >
                                    Show Entries :{" "}
                                </Text>
                                <Center>
                                    <LeadsWatchSelect
                                        control={control}
                                        name={"select"}
                                        list={["10", "20", "25", "30"]}
                                        value={selectCount}
                                        handleChange={(value) => handleChangeSelect(parseInt(value))}
                                        width={aw(70)}
                                        height={ah(32)}
                                    />
                                </Center>
                            </HStack>
                        </HStack>
                    </VStack>
                    <Box>
                        <VStack width={aw(1100)}>
                            <HStack
                                width={aw(1100)}
                                alignItems={"center"}
                                bgColor={"#D7D7D7"}
                                style={{
                                    height: ah(60),
                                    background: "#F9FAFB 0% 0% no-repeat padding-box",
                                    borderLeft: "1px solid #D7D7D7",
                                    borderRight: "1px solid #D7D7D7",
                                    borderTop: "1px solid #D7D7D7",
                                    borderBottom: "1px solid #D7D7D7",
                                    borderTopLeftRadius: aw(10),
                                    borderTopRightRadius: aw(10),
                                }}
                            >
                                {[
                                    "S.No",
                                    "Name",
                                    "Email",
                                    "Phone",
                                    "Role",
                                    "Users",
                                    "Team Dashboard",
                                ].map((val) => (
                                    <HStack
                                        width={
                                            val === "S.No"
                                                ? aw(50)
                                                : val === "Email" || val === "Name" ? aw(200) :
                                                    val === "Role" ? aw(110) : aw(150)
                                        }
                                        justifyContent="flex-start"
                                        alignItems={"center"}
                                        key={val}
                                        ml={aw(10)}
                                    >
                                        <Text
                                            pl={aw(30)}
                                            fontSize={aw(14)}
                                            fontFamily={"RobotoMedium"}
                                            color="#212121"
                                        >
                                            {val}
                                        </Text>
                                    </HStack>
                                ))}
                            </HStack>
                            {!isLoading ? (
                                <ScrollView
                                    maxHeight={ah(400)}
                                    showsVerticalScrollIndicator={false}
                                    style={{
                                        borderBottomRightRadius: aw(10),
                                        borderBottomLeftRadius: aw(10),
                                        borderbottomWidth: "1px solid #D7D7D7",
                                        borderTopWidth: "0px",
                                        borderColor: "#D7D7D7",
                                        borderWidth: "1px",
                                    }}
                                >
                                    <FlatList
                                        ListEmptyComponent={
                                            <HStack
                                                width={aw(1100)}
                                                justifyContent="center"
                                                alignItems={"center"}
                                                height={ah(400)}
                                            >
                                                <Text
                                                    color="black"
                                                    fontSize={aw(14)}
                                                    fontFamily={"RobotoMedium"}
                                                >
                                                    No data found
                                                </Text>
                                            </HStack>
                                        }
                                        data={teams && teams.list}
                                        keyExtractor={(item) => item._id}
                                        renderItem={({ item, index }) => (
                                            <HStack
                                                //   minHeight={ah(60)}
                                                width={aw(1100)}
                                                // justifyContent="space-evenly"
                                                alignItems={"center"}
                                                style={{
                                                    backgroundColor:
                                                        index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                                                    minHeight: ah(58),
                                                    borderTopWidth: index === 0 ? 0 : "1px",
                                                    borderLeftWidth: "0px",
                                                    borderRightWidth: "0px",
                                                    borderBottomWidth: "0px",
                                                    borderColor: "#D7D7D7",
                                                }}
                                            >
                                                {[
                                                    "index",
                                                    "name",
                                                    "email",
                                                    "phone",
                                                    "role",
                                                    "users",
                                                    "team dashboard"
                                                ].map((val) => (
                                                    <HStack
                                                        // width={aw(200)}
                                                        width={
                                                            val === "index"
                                                                ? aw(50)
                                                                : val === "email" || val === "name" ? aw(200) :
                                                                    val === "role" ? aw(110) : aw(150)
                                                        }
                                                        justifyContent="flex-start"
                                                        alignItems={"center"}
                                                        key={val}
                                                        ml={aw(10)}
                                                    >
                                                        <Text
                                                            pl={val === "index" ? aw(35) : aw(30)}
                                                            fontSize={aw(14)}
                                                            fontFamily={"RobotoRegular"}
                                                            color="#212121"
                                                        >
                                                            {val === "index" ||
                                                                val === "name"
                                                                ? val === "index"
                                                                    ? (currentPage - 1) * 10 + index + 1
                                                                    :
                                                                    item && item["lastname"]
                                                                        ? item && `${item["firstname"]} ${item["lastname"]}`
                                                                        : item && item["firstname"] ? item["firstname"] : "_"

                                                                : val === "users" ?
                                                                    item.users.length ?
                                                                        <LeadsWatchMenu width={aw(150)} title={<Text color={"blue.700"}>{item.users.length}</Text>}>
                                                                            {item.users.map((user, index) => (
                                                                                <Menu.Item key={index} onPress={() => handleUserClick(user)}>
                                                                                    <Text fontSize={aw(14)} fontFamily={"RobotoRegular"} color="#212121">{user.firstname}</Text>
                                                                                </Menu.Item>
                                                                            ))}

                                                                        </LeadsWatchMenu> : "0"

                                                                    :
                                                                    val === "team dashboard" ? <LeadsWatchButton label={"Team DashBoard"} onPress={() => handleTeamDashBoard(item)}></LeadsWatchButton> :
                                                                        item && item[val] ? item[val] : "_"}

                                                            {/* {index === 1 ?
                                                            JSON.stringify(item.owners[0]['firstname']) : null}   */}
                                                        </Text>
                                                    </HStack>
                                                ))}
                                            </HStack>
                                        )}
                                    />
                                </ScrollView>
                            ) : (
                                <HStack
                                    justifyContent="center"
                                    alignItems={"center"}
                                    height={ah(400)}
                                    style={{
                                        border: "1px solid #D7D7D7",
                                        borderTopWidth: 0,
                                        borderBottomLeftRadius: aw(10),
                                        borderBottomRightRadius: aw(10),
                                    }}
                                >
                                    <Spinner size="lg" color="#2230F8" />
                                </HStack>
                            )}
                        </VStack>
                    </Box>
                    <VStack bottom={aw(5)} position="fixed">
                        <LeadsWatchPagination
                            totalCount={
                                teams && teams.total_count ? teams.total_count : 0
                            }
                            currentPage={currentPage}
                            pageSize={selectCount}
                            onPageChange={(currentPage) => {
                                setCurrentPage(currentPage);
                            }}
                        />
                    </VStack>
                </VStack>
            </VStack>
            <LeadsWatchModal showModal={open} onClose={() => setOpen(false)}>

            </LeadsWatchModal>
        </>
    )

}
export default Teams;