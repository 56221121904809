import React, { useState, useEffect } from "react";
import {
  Text,
  HStack,
  VStack,
  Box,
  Checkbox,
  Center,
  Image,
  Divider,
  Spinner,
  Alert,
  useToast,
  IconButton,
  CloseIcon,
  Pressable,
  FlatList,
  ScrollView,
  Modal,
} from "native-base";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import moment from "moment/moment";

import { aw, ah } from "../../utils";
import ToastAlert from "../../components/ToastAlert";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchTopBar from "../../components/LeadsWatchTopBar";
import { allActivities } from "../../store/actions/activity";
import { register } from "../../store/actions/auth";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchSearch from "../../components/LeadsWatchSearch";
import LeadsWatchSearchIcon from "../../components/svg/LeadsWatchSearchIcon";
import LeadsWatchInput from "../../components/LeadsWatchInput";
import LeadsWatchEyeIcon from "../../components/svg/LeadsWatchEyeIcon";
import LeadsWatchEyeOffIcon from "../../components/svg/LeadsWatchEyeOffIcon";

const Activity = () => {
  const [show, setShow] = useState(false);
  const details = useSelector((state) => state.activities.allActivities);
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectCount, setSeletCount] = useState("10");
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const time = localStorage.getItem("time");
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    mode: "all",
    defaultValues: {
      select: "10",
      refreshTime: `${time}(s)`,
    },
  });
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    reset: reset1,
    getValues: getValues1,
  } = useForm({
    mode: "all",
  });

  const handleChangeSelect = (value) => {
    setSeletCount(value);
    setCurrentPage(1);
    setAutoRefresh(false);
  };
  const fetchDeatils = async () => {
    // setIsLoading(true);
    try {
      await dispatch(allActivities(currentPage, selectCount, search));
    } catch (err) {
      toast.show({
        placement: "top",
        render: () => {
          return (
            <ToastAlert
              desc={err.message}
              // handleClose={() => toast.close()}
              status="error"
              title="error"
            />
          );
        },
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchDeatils();
    if (autoRefresh) {
      const rTime = parseInt(localStorage.getItem("time"));
      // alert(rTime);
      const interval = setInterval(() => fetchDeatils(), rTime * 1000);
      return () => {
        clearInterval(interval);
      };
    }
    // if (currentPage === 1) {
    //   const rTime = parseInt(localStorage.getItem("time"));
    //   alert(rTime);
    //   setTimeout(() => fetchDeatils(), [time * 1000]);
    // }
  }, [currentPage, selectCount, search, autoRefresh, refresh, search]);
  const handleChangeRefreshTime = (value) => {
    console.log(value);
    const rTime = value.slice(0, 2);
    // setTime(parseInt(value));
    localStorage.setItem("time", rTime);
    setIsOpen(false);
    setRefresh(!refresh);
  };
  const handleSearch = (data) => {
    console.log(data);
    setSearch(data.search);
    setCurrentPage(1);
    setAutoRefresh(false);
  };
  console.log(search, autoRefresh);
  const onSubmit = async (data) => {
    console.log(data);
    setLoading(true);
    try {
      await dispatch(
        register(data.firstName, data.lastName, data.email, data.password)
      );
      toast.show({
        placement: "top",
        render: () => {
          return (
            <ToastAlert
              desc={"user added successfully"}
              // handleClose={() => toast.close()}
              status="success"
              title="success"
            />
          );
        },
      });
      reset({ email: "" });
      setAddUserModalOpen(false);
    } catch (err) {
      toast.show({
        placement: "top",
        render: () => {
          return (
            <ToastAlert
              desc={err.message}
              // handleClose={() => toast.close()}
              status="error"
              title="error"
            />
          );
        },
      });
    }
    setLoading(false);
  };
  return (
    <VStack width={aw(1100)}>
      <VStack ml={aw(55)} space={ah(18)} width={aw(1100)}>
        <HStack
          justifyContent="space-between"
          alignItems={"center"}
          width={aw(1100)}
        >
          <VStack justifyContent={"center"}>
            <HStack
              justifyContent={"space-between"}
              width={aw(1100)}
              alignItems={"center"}
            >
              <VStack>
                <HStack space={aw(8)} alignItems={"baseline"}>
                  <Text
                    fontSize={aw(26)}
                    color={"#121233"}
                    fontFamily="RobotoMedium"
                  >
                    Activity
                  </Text>
                  <Text
                    fontSize={aw(18)}
                    color={"#919191"}
                    fontFamily="RobotoMedium"
                  >
                    {details && details.count}
                  </Text>
                </HStack>

                <Text
                  lineHeight={ah(34)}
                  fontSize={aw(14)}
                  fontFamily={"RobotoRegular"}
                  color={"#919191"}
                  fontWeight={400}
                >
                  Your all activity list will be shown here
                </Text>
              </VStack>
              <LeadsWatchSearch
                control={control1}
                name="search"
                inputRightElement={
                  <Pressable
                    w={aw(30)}
                    onPress={handleSubmit1(handleSearch)}
                    // pr={aw(10)}
                  >
                    <LeadsWatchSearchIcon />
                  </Pressable>
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    console.log(e);
                    handleSubmit1(
                      handleSearch({ search: getValues1("search") })
                    );
                  }
                }}
              />
            </HStack>
          </VStack>
        </HStack>
        <Divider bg="#E6E7E9" />
        <VStack height={ah(35)}>
          <HStack justifyContent={"space-between"}>
            <HStack height={ah(32)} alignItems="center">
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                Show Entries :{" "}
              </Text>
              <Center>
                <LeadsWatchSelect
                  control={control}
                  name={"select"}
                  list={["10", "15", "20", "25"]}
                  value={selectCount}
                  handleChange={(value) => handleChangeSelect(parseInt(value))}
                  width={aw(70)}
                  height={ah(32)}
                />
              </Center>
            </HStack>
            <HStack space={aw(5)}>
              <LeadsWatchButton
                onPress={() => {
                  if (autoRefresh) {
                    setIsOpen(true);
                  } else {
                    setAutoRefresh(true);
                    setCurrentPage(1);
                  }
                }}
                color="#FAFBFD"
                style={{
                  width: aw(100),
                  height: ah(40),
                  borderRadius: "10px",
                }}
                label="Auto Refresh"
                bg="#0000FF"
                borderColor="#FAFBFD"
              />
              <LeadsWatchButton
                onPress={() => setAddUserModalOpen(true)}
                label="Add User"
                style={{
                  width: aw(100),
                  height: ah(40),
                  borderRadius: "10px",
                }}
                bg="#0000FF"
                borderColor="#FAFBFD"
                color="#FAFBFD"
              />
            </HStack>
          </HStack>
        </VStack>
        <Box>
          <VStack width={aw(1100)}>
            <HStack
              width={aw(1100)}
              justifyContent="space-evenly"
              alignItems={"center"}
              bgColor="#D7D7D7"
              style={{
                height: ah(60),
                background: "#F9FAFB 0% 0% no-repeat padding-box",
                borderLeft: "1px solid #D7D7D7",
                borderRight: "1px solid #D7D7D7",
                borderTop: "1px solid #D7D7D7",
                borderBottom: "1px solid #D7D7D7",
                borderTopLeftRadius: aw(10),
                borderTopRightRadius: aw(10),
              }}
            >
              {["S.No", "Title", "Description", "Created"].map((val) => (
                <HStack
                  width={
                    val === "S.No" || val === "Description"
                      ? val === "S.No"
                        ? aw(50)
                        : aw(350)
                      : aw(200)
                  }
                  justifyContent="flex-start"
                  alignItems={"center"}
                  key={val}
                  ml={aw(10)}
                >
                  <Text
                    pl={aw(30)}
                    fontSize={aw(14)}
                    fontFamily={"RobotoMedium"}
                    color="#212121"
                  >
                    {val}
                  </Text>
                </HStack>
              ))}
            </HStack>
            {!isLoading ? (
              <ScrollView
                maxHeight={ah(400)}
                showsVerticalScrollIndicator={false}
                style={{
                  borderBottomRightRadius: aw(10),
                  borderBottomLeftRadius: aw(10),
                  borderbottomWidth: "1px solid #D7D7D7",
                  borderTopWidth: "0px",
                  borderColor: "#D7D7D7",
                  borderWidth: "1px",
                }}
              >
                <FlatList
                  ListEmptyComponent={
                    <HStack
                      width={aw(1100)}
                      justifyContent="center"
                      alignItems={"center"}
                      height={ah(400)}
                    >
                      <Text
                        color="black"
                        fontSize={aw(14)}
                        fontFamily={"RobotoMedium"}
                      >
                        No data found
                      </Text>
                    </HStack>
                  }
                  data={details && details.data}
                  keyExtractor={(item) => item._id}
                  renderItem={({ item, index }) => (
                    <HStack
                      width={aw(1100)}
                      justifyContent="space-evenly"
                      alignItems={"center"}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                        minHeight: ah(58),
                        borderTopWidth: index === 0 ? 0 : "1px",
                        borderLeftWidth: "0px",
                        borderRightWidth: "0px",
                        borderBottomWidth: "0px",
                        borderColor: "#D7D7D7",
                      }}
                    >
                      {["index", "title", "desc", "created"].map((val) => (
                        <HStack
                          // width={aw(200)}
                          width={
                            val === "index" || val === "desc"
                              ? val === "index"
                                ? aw(50)
                                : aw(350)
                              : aw(200)
                          }
                          justifyContent="flex-start"
                          alignItems={"center"}
                          key={val}
                          ml={aw(10)}
                        >
                          <Text
                            pl={aw(30)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoRegular"}
                            color="#212121"
                          >
                            {val === "index" || val === "created"
                              ? val === "index"
                                ? index + 1
                                : moment(item[val]).format("YYYY-MM-DD")
                              : item[val]}
                          </Text>
                        </HStack>
                      ))}
                    </HStack>
                  )}
                />
              </ScrollView>
            ) : (
              <HStack
                justifyContent="center"
                alignItems={"center"}
                height={ah(400)}
                style={{
                  border: "1px solid #D7D7D7",
                  borderTopWidth: 0,
                  borderBottomLeftRadius: aw(10),
                  borderBottomRightRadius: aw(10),
                }}
              >
                <Spinner size="lg" color="#2230F8" />
              </HStack>
            )}
          </VStack>
        </Box>
        <VStack bottom={aw(5)} position="fixed">
          <LeadsWatchPagination
            totalCount={details && details.count ? details.count : 0}
            currentPage={currentPage}
            pageSize={selectCount}
            onPageChange={(currentPage) => {
              setCurrentPage(currentPage);
              setAutoRefresh(false);
            }}
          />
        </VStack>
      </VStack>
      <LeadsWatchModal
        height={ah(600)}
        width={aw(400)}
        showModal={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        heading={
          <HStack space={ah(10)}>
            <Text
              color={"#000000"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            >
              Select Refresh time
            </Text>
          </HStack>
        }
      >
        <HStack height={ah(32)} alignItems="center" marginBottom={ah(40)}>
          <Text fontSize={aw(14)} fontFamily="RobotoRegular" color="#212121">
            Select Refresh Time :{" "}
          </Text>

          <LeadsWatchSelect
            control={control}
            name={"refreshTime"}
            list={["10(s)", "15(s)", "30(s)", "60(s)"]}
            // value={selectCount}
            handleChange={(value) => handleChangeRefreshTime(value)}
            width={aw(200)}
            height={ah(32)}
          />
        </HStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        height={ah(600)}
        width={aw(400)}
        showModal={addUserModalOpen}
        onClose={() => {
          setAddUserModalOpen(false);
        }}
        heading={
          <HStack space={ah(10)}>
            <Text
              color={"#000000"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            >
              Add User
            </Text>
          </HStack>
        }
      >
        <VStack space={ah(10)} justifyContent={"center"} alignItems={"center"}>
          <LeadsWatchInput
            label={
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                First Name :
              </Text>
            }
            style={{ marginBottom: ah(20), width: aw(350) }}
            control={control}
            name="firstName"
            placeholder="Enter first name"
            rules={{
              required: {
                value: true,
              },
            }}
            invalid={errors.firstName ? true : false}
            error={errors.firstName ? errors.firstName.message : ""}
          />
          <LeadsWatchInput
            label={
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                Last Name :
              </Text>
            }
            style={{ marginBottom: ah(20), width: aw(350) }}
            control={control}
            name="lastName"
            placeholder="Enter last name"
            rules={{
              required: {
                value: true,
              },
            }}
            invalid={errors.lastName ? true : false}
            error={errors.lastName ? errors.lastName.message : ""}
          />
          <LeadsWatchInput
            label={
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                Email :
              </Text>
            }
            style={{ marginBottom: ah(20), width: aw(350) }}
            control={control}
            name="email"
            placeholder="enter email"
            rules={{
              required: {
                value: true,
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              },
            }}
            invalid={errors.email ? true : false}
            error={errors.email ? errors.email.message : ""}
          />
          <LeadsWatchInput
            label={
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                Password :
              </Text>
            }
            style={{ marginBottom: ah(30), width: aw(350) }}
            width={aw(350)}
            control={control}
            name="password"
            placeholder="Enter password"
            rules={{
              required: {
                value: true,
              },
              // pattern: {
              //   value:
              //     /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
              // },
            }}
            invalid={errors.password ? true : false}
            error={errors.password ? errors.password.message : ""}
            type={show ? "text" : "password"}
            rightIcon={
              !show ? (
                <Pressable onPress={() => setShow(!show)}>
                  <LeadsWatchEyeOffIcon />
                </Pressable>
              ) : (
                <Pressable onPress={() => setShow(!show)}>
                  <LeadsWatchEyeIcon />
                </Pressable>
              )
            }
          />
          <LeadsWatchButton
            isLoading={loading}
            width={aw(350)}
            onPress={handleSubmit(onSubmit)}
            style={{ height: ah(40), width: aw(350) }}
            label="Add"
            color="#FAFBFD"
            bg="#0000FF"
            borderColor="#FAFBFD"
          />
        </VStack>
      </LeadsWatchModal>
    </VStack>
  );
};

export default Activity;
