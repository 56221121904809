import React, { useState, useEffect } from "react";
import {
  Text,
  HStack,
  VStack,
  Box,
  Center,
  Divider,
  Spinner,
  useToast,
  Pressable,
  FlatList,
  ScrollView,
} from "native-base";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import moment from "moment/moment";

import { aw, ah } from "../../utils";
import ToastAlert from "../../components/ToastAlert";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import { allRegisters } from "../../store/actions/registers";
import LeadsWatchSearch from "../../components/LeadsWatchSearch";
import LeadsWatchSearchIcon from "../../components/svg/LeadsWatchSearchIcon";

const Registers = () => {
  const [show, setShow] = useState(false);
  const registers = useSelector((state) => state.registers.allRegisters);
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [selectCount, setSeletCount] = useState("10");
  const [isLoading, setIsLoading] = useState(true);

  const [search, setSearch] = useState("");

  const time = localStorage.getItem("time");
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    mode: "all",
    defaultValues: {
      select: "10",
      refreshTime: `${time}(s)`,
    },
  });
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    reset: reset1,
    getValues: getValues1,
  } = useForm({
    mode: "all",
  });

  const handleChangeSelect = (value) => {
    setSeletCount(value);
    setCurrentPage(1);
  };
  const fetchDeatils = async () => {
    // setIsLoading(true);
    try {
      await dispatch(allRegisters(currentPage, selectCount, search));
    } catch (err) {
      toast.show({
        placement: "top",
        render: () => {
          return (
            <ToastAlert
              desc={err.message}
              // handleClose={() => toast.close()}
              status="error"
              title="error"
            />
          );
        },
      });
    }
    setIsLoading(false);
  };

  const handleSearch = (data) => {
    console.log(data);
    setSearch(data.search);
    setCurrentPage(1);
  };
  useEffect(() => {
    fetchDeatils();
  }, [search, currentPage, selectCount]);
  return (
    <VStack width={aw(1100)}>
      <VStack ml={aw(55)} space={ah(18)} width={aw(1100)}>
        <HStack
          justifyContent="space-between"
          alignItems={"center"}
          width={aw(1100)}
        >
          <VStack justifyContent={"center"}>
            <HStack
              justifyContent={"space-between"}
              width={aw(1100)}
              alignItems={"center"}
            >
              <VStack>
                <HStack space={aw(8)} alignItems={"baseline"}>
                  <Text
                    fontSize={aw(26)}
                    color={"#121233"}
                    fontFamily="RobotoMedium"
                  >
                    Registers
                  </Text>
                  <Text
                    fontSize={aw(18)}
                    color={"#919191"}
                    fontFamily="RobotoMedium"
                  >
                    {registers && registers.total_count}
                  </Text>
                </HStack>

                <Text
                  lineHeight={ah(34)}
                  fontSize={aw(14)}
                  fontFamily={"RobotoRegular"}
                  color={"#919191"}
                  fontWeight={400}
                >
                  Your all activity list will be shown here
                </Text>
              </VStack>
              <LeadsWatchSearch
                control={control1}
                name="search"
                inputRightElement={
                  <Pressable
                    w={aw(30)}
                    onPress={handleSubmit1(handleSearch)}
                  // pr={aw(10)}
                  >
                    <LeadsWatchSearchIcon />
                  </Pressable>
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    console.log(e);
                    handleSubmit1(
                      handleSearch({ search: getValues1("search") })
                    );
                  }
                }}
              />
            </HStack>
          </VStack>
        </HStack>
        <Divider bg="#E6E7E9" />
        <VStack height={ah(35)}>
          <HStack justifyContent={"space-between"}>
            <HStack height={ah(32)} alignItems="center">
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                Show Entries :{" "}
              </Text>
              <Center>
                <LeadsWatchSelect
                  control={control}
                  name={"select"}
                  list={["10", "15", "20", "25"]}
                  value={selectCount}
                  handleChange={(value) => handleChangeSelect(parseInt(value))}
                  width={aw(70)}
                  height={ah(32)}
                />
              </Center>
            </HStack>
          </HStack>
        </VStack>
        <Box>
          <VStack width={aw(1100)}>
            <HStack
              width={aw(1100)}
              // justifyContent="space-evenly"
              alignItems={"center"}
              bgColor="#D7D7D7"
              style={{
                height: ah(60),
                background: "#F9FAFB 0% 0% no-repeat padding-box",
                borderLeft: "1px solid #D7D7D7",
                borderRight: "1px solid #D7D7D7",
                borderTop: "1px solid #D7D7D7",
                borderBottom: "1px solid #D7D7D7",
                borderTopLeftRadius: aw(10),
                borderTopRightRadius: aw(10),
              }}
            >
              {[
                "S.No",
                "Name",
                "Email",
                "Phone",
                "Role",
                "Company",
                "Date",
              ].map((val) => (
                <HStack
                  width={
                    val === "S.No" || val === "Email"
                      ? val === "S.No"
                        ? aw(50)
                        : aw(250)
                      : aw(150)
                  }
                  justifyContent="flex-start"
                  alignItems={"center"}
                  key={val}
                  ml={aw(10)}
                >
                  <Text
                    pl={aw(30)}
                    fontSize={aw(14)}
                    fontFamily={"RobotoMedium"}
                    color="#212121"
                  >
                    {val}
                  </Text>
                </HStack>
              ))}
            </HStack>
            {!isLoading ? (
              <ScrollView
                maxHeight={ah(400)}
                showsVerticalScrollIndicator={false}
                style={{
                  borderBottomRightRadius: aw(10),
                  borderBottomLeftRadius: aw(10),
                  borderbottomWidth: "1px solid #D7D7D7",
                  borderTopWidth: "0px",
                  borderColor: "#D7D7D7",
                  borderWidth: "1px",
                }}
              >
                <FlatList
                  ListEmptyComponent={
                    <HStack
                      width={aw(1100)}
                      justifyContent="center"
                      alignItems={"center"}
                      height={ah(400)}
                    >
                      <Text
                        color="black"
                        fontSize={aw(14)}
                        fontFamily={"RobotoMedium"}
                      >
                        No data found
                      </Text>
                    </HStack>
                  }
                  data={registers && registers.list}
                  keyExtractor={(item) => item._id}
                  renderItem={({ item, index }) => (
                    <HStack
                      //   minHeight={ah(60)}
                      width={aw(1100)}
                      // justifyContent="space-evenly"
                      alignItems={"center"}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                        minHeight: ah(58),
                        borderTopWidth: index === 0 ? 0 : "1px",
                        borderLeftWidth: "0px",
                        borderRightWidth: "0px",
                        borderBottomWidth: "0px",
                        borderColor: "#D7D7D7",
                      }}
                    >
                      {[
                        "index",
                        "name",
                        "email",
                        "phone",
                        "role",
                        "company",
                        "created",
                      ].map((val) => (
                        <HStack
                          // width={aw(200)}
                          width={
                            val === "index" || val === "email"
                              ? val === "index"
                                ? aw(50)
                                : aw(250)
                              : aw(150)
                          }
                          justifyContent="flex-start"
                          alignItems={"center"}
                          key={val}
                          ml={aw(10)}
                        >
                          <Text
                            pl={val === "index" ? aw(35) : aw(30)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoRegular"}
                            color="#212121"
                          >
                            {val === "index" ||
                              val === "name" ||
                              val === "created"
                              ? val === "index"
                                ? index + 1
                                : val === "name"
                                  ? item["lastname"]
                                    ? `${item["firstname"]} ${item["lastname"]}`
                                    : item["firstname"]
                                  : `${moment(item[val]).format(
                                    "DD:MM:YYYY"
                                  )}              ${moment(item[val]).format(
                                    "hh:mm:ss"
                                  )}`
                              : item[val]}
                          </Text>
                        </HStack>
                      ))}
                    </HStack>
                  )}
                />
              </ScrollView>
            ) : (
              <HStack
                justifyContent="center"
                alignItems={"center"}
                height={ah(400)}
                style={{
                  border: "1px solid #D7D7D7",
                  borderTopWidth: 0,
                  borderBottomLeftRadius: aw(10),
                  borderBottomRightRadius: aw(10),
                }}
              >
                <Spinner size="lg" color="#2230F8" />
              </HStack>
            )}
          </VStack>
        </Box>
        <VStack bottom={aw(5)} position="fixed">
          <LeadsWatchPagination
            totalCount={
              registers && registers.total_count ? registers.total_count : 0
            }
            currentPage={currentPage}
            pageSize={selectCount}
            onPageChange={(currentPage) => {
              setCurrentPage(currentPage);
            }}
          />
        </VStack>
      </VStack>
    </VStack>
  );
};

export default Registers;
