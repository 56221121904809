import React, { useState, useEffect } from "react";
import {
    VStack,
    Spinner,
    Box,
    HStack,
    Badge,
    Text,
    Divider,
    ScrollView,
    FlatList,
    Pressable,
    Menu,
    Center,

} from "native-base";
import { aw, ah } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchMenu from "../../components/LeadsWatchMenu";
import LeadsWatchEyeIcon from "../../components/svg/LeadsWatchEyeIcon";
import { allPropertyteam } from "../../store/actions/teams";
import LeadsWatchSearch from "../../components/LeadsWatchSearch";
import { useForm } from "react-hook-form";
import LeadsWatchSearchIcon from "../../components/svg/LeadsWatchSearchIcon";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../components/LeadsWatchButton";

const PropertiesTeam = () => {
    const navigate = useNavigate()
    const propertyData = useSelector((state) => state.properties.allPropertyteam);
    const time = localStorage.getItem("time");
    const [isLoading, setIsLoading] = useState(true);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [isModalVisible, setModalVisible] = useState(false);
    const { id } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectCount, setSeletCount] = useState("10");
    const [search, setSearch] = useState("");

    const handleChangeSelect = (value) => {
        setSeletCount(value);
        setCurrentPage(1);
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        getValues,
    } = useForm({
        mode: "all",
        defaultValues: {
            select: "10",
            refreshTime: `${time}(s)`,
        },
    });

    const handleSearch = (data) => {
        console.log(data);
        setSearch(data.search);
        setCurrentPage(1);
    };

    const {
        control: control1,
        handleSubmit: handleSubmit1,
        formState: { errors: errors1 },
        reset: reset1,
        getValues: getValues1,
    } = useForm({
        mode: "all",
    });

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchDashboard = async () => {
            setIsLoading(true);
            try {
                await dispatch(allPropertyteam(id, selectCount, currentPage, search));
            } catch (err) {
                console.log(err);
            }
            setIsLoading(false);
        }
        fetchDashboard();
    }, [id, currentPage, selectCount, search]);

    const propertiesList = propertyData ? propertyData.list : [];
    console.log("propertiesList", propertiesList);
    const totalCount = propertyData ? propertyData.total_count : 0;

    return (
        <VStack width={aw(1000)}>
            <VStack ml={aw(55)} space={ah(18)} width={aw(1000)}>
                <HStack
                    justifyContent="space-between"
                    alignItems={"center"}
                    width={aw(1100)}
                >
                    <VStack justifyContent={"center"}>
                        <HStack
                            justifyContent={"space-between"}
                            width={aw(1100)}
                            alignItems={"center"}
                        >
                            <VStack>
                                <HStack space={aw(8)} alignItems={"baseline"}>
                                    <Text
                                        fontSize={aw(26)}
                                        color={"#121233"}
                                        fontFamily="RobotoMedium"
                                    >
                                        Properties
                                    </Text>
                                    <Text
                                        fontSize={aw(18)}
                                        color={"#919191"}
                                        fontFamily="RobotoMedium"
                                    >
                                        {totalCount}
                                    </Text>
                                </HStack>
                                <Text
                                    lineHeight={ah(34)}
                                    fontSize={aw(14)}
                                    fontFamily={"RobotoRegular"}
                                    color={"#919191"}
                                    fontWeight={400}
                                >
                                    all properties list will be shown here
                                </Text>

                            </VStack>
                            <LeadsWatchSearch
                                control={control1}
                                name="search"
                                inputRightElement={
                                    <Pressable
                                        w={aw(30)}
                                        onPress={handleSubmit1(handleSearch)}
                                    // pr={aw(10)}
                                    >
                                        <LeadsWatchSearchIcon />
                                    </Pressable>
                                }
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        console.log(e);
                                        handleSubmit1(
                                            handleSearch({ search: getValues1("search") })
                                        );
                                    }
                                }}
                            />

                        </HStack>
                    </VStack>

                </HStack>
                <Divider bg="#E6E7E9" />
                <VStack height={ah(35)}>
                    <HStack justifyContent={"space-between"}>
                        <HStack height={ah(32)} alignItems="center">
                            <Text
                                fontSize={aw(14)}
                                fontFamily="RobotoRegular"
                                color="#212121"
                            >
                                Show Entries :{" "}
                            </Text>
                            <Center>
                                <LeadsWatchSelect
                                    control={control}
                                    name={"select"}
                                    list={["10", "15", "20", "25"]}
                                    value={selectCount}
                                    handleChange={(value) => handleChangeSelect(parseInt(value))}
                                    width={aw(70)}
                                    height={ah(32)}
                                />
                            </Center>

                        </HStack>
                        <HStack justifyContent={"flex-end"}>
                            <LeadsWatchButton
                                color="#FAFBFD"
                                onPress={() => {
                                    navigate(-1)
                                }}
                                style={{
                                    width: aw(100),
                                    height: ah(40),
                                    borderRadius: "10px",
                                }}
                                label={"Back"}

                                bg="#0000FF"
                                borderColor="#FAFBFD"
                            />
                        </HStack>
                    </HStack>
                </VStack>
                <Box>
                    <VStack width={aw(1000)}>
                        <HStack
                            width={aw(1000)}
                            // justifyContent="space-evenly"
                            alignItems={"center"}
                            bgColor="#D7D7D7"
                            style={{
                                height: ah(60),
                                background: "#F9FAFB 0% 0% no-repeat padding-box",
                                borderLeft: "1px solid #D7D7D7",
                                borderRight: "1px solid #D7D7D7",
                                borderTop: "1px solid #D7D7D7",
                                borderBottom: "1px solid #D7D7D7",
                                borderTopLeftRadius: aw(10),
                                borderTopRightRadius: aw(10),
                            }}
                        >
                            {[
                                "S.No",
                                "Address",
                                "Built-Year",
                                "Bed",
                                "Bath",
                                "Sqft",
                                "PropertyID",
                                "Date",
                                "Details"
                            ].map((val) => (
                                <HStack
                                    width={
                                        val === "S.No" ? aw(50) :
                                            val === "Address" ? aw(200) :
                                                val === "Built-Year" ? aw(110) :
                                                    val === "Bed" ? aw(80) :
                                                        val === "Bath" ? aw(60) :
                                                            val === "Sqft" ? aw(60) :
                                                                val === "PropertyID" ? aw(90) :
                                                                    aw(100) // Default width for unknown fields
                                    }
                                    justifyContent="flex-start"
                                    alignItems={"center"}
                                    key={val}
                                    ml={aw(10)}
                                >
                                    <Text
                                        pl={aw(30)}
                                        fontSize={aw(14)}
                                        fontFamily={"RobotoMedium"}
                                        color="#212121"
                                    >
                                        {val}
                                    </Text>
                                </HStack>
                            ))}
                        </HStack>
                        {!isLoading ? (
                            <ScrollView
                                maxHeight={ah(400)}
                                showsVerticalScrollIndicator={false}
                                style={{
                                    borderBottomRightRadius: aw(10),
                                    borderBottomLeftRadius: aw(10),
                                    borderbottomWidth: "1px solid #D7D7D7",
                                    borderTopWidth: "0px",
                                    borderColor: "#D7D7D7",
                                    borderWidth: "1px",
                                }}
                            >
                                <FlatList
                                    ListEmptyComponent={
                                        <HStack
                                            width={aw(1100)}
                                            justifyContent="center"
                                            alignItems={"center"}
                                            height={ah(400)}
                                        >
                                            <Text
                                                color="black"
                                                fontSize={aw(14)}
                                                fontFamily={"RobotoMedium"}
                                            >
                                                No data found
                                            </Text>
                                        </HStack>
                                    }
                                    data={propertiesList}
                                    keyExtractor={(item) => item._id}

                                    renderItem={({ item, index }) => (
                                        <HStack
                                            //   minHeight={ah(60)}
                                            width={aw(1100)}
                                            // justifyContent="space-evenly"
                                            alignItems={"center"}
                                            style={{
                                                backgroundColor:
                                                    index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                                                minHeight: ah(58),
                                                borderTopWidth: index === 0 ? 0 : "1px",
                                                borderLeftWidth: "0px",
                                                borderRightWidth: "0px",
                                                borderBottomWidth: "0px",
                                                borderColor: "#D7D7D7",
                                            }}
                                        >
                                            {[
                                                "index",
                                                "propertyaddress",
                                                "yearbuilt",
                                                "bed",
                                                "bath",
                                                "sqft",
                                                "tid",
                                                "created",
                                                "details"
                                            ].map((val) => (
                                                <HStack
                                                    // width={aw(200)}
                                                    width={
                                                        val === "index" ? aw(50) :
                                                            val === "propertyaddress" ? aw(200) :
                                                                val === "yearbuilt" ? aw(120) :
                                                                    val === "bed" ? aw(80) :
                                                                        val === "bath" ? aw(60) :
                                                                            val === "sqft" ? aw(60) :
                                                                                val === "tid" ? aw(60) :
                                                                                    val === "created" ? aw(160) :
                                                                                        aw(250) // Default width for unknown fields
                                                    }
                                                    justifyContent="flex-start"
                                                    alignItems={"center"}
                                                    key={val}
                                                    ml={aw(10)}
                                                >
                                                    <Text
                                                        pl={val === "index" ? aw(35) : aw(30)}
                                                        fontSize={aw(14)}
                                                        fontFamily={"RobotoRegular"}
                                                        color="#212121"
                                                    >
                                                        {val === "index" ||
                                                            val === "name" ||
                                                            val === "created"
                                                            ? val === "index"
                                                                ? index + 1
                                                                : val === "name"
                                                                    ? item["lastname"]
                                                                        ? `${item["firstname"]} ${item["lastname"]}`
                                                                        : item["firstname"]
                                                                    : `${moment(item[val]).format(
                                                                        "DD:MM:YYYY"
                                                                    )}              ${moment(item[val]).format(
                                                                        "hh:mm:ss"
                                                                    )}`
                                                            : val === "details"
                                                                ? <Pressable onPress={() => navigate(`/teams/prop/${item._id}`)}>
                                                                    <LeadsWatchEyeIcon />
                                                                </Pressable>
                                                                : item[val]}
                                                    </Text>
                                                </HStack>
                                            ))}
                                        </HStack>
                                    )}
                                />
                            </ScrollView>
                        ) : (
                            <HStack
                                justifyContent="center"
                                alignItems={"center"}
                                height={ah(400)}
                                style={{
                                    border: "1px solid #D7D7D7",
                                    borderTopWidth: 0,
                                    borderBottomLeftRadius: aw(10),
                                    borderBottomRightRadius: aw(10),
                                }}
                            >
                                <Spinner size="lg" color="#2230F8" />
                            </HStack>
                        )}
                    </VStack>
                </Box>
                <VStack bottom={aw(5)} position="fixed">
                    <LeadsWatchPagination
                        totalCount={
                            totalCount ? totalCount : 0
                        }
                        currentPage={currentPage}
                        pageSize={selectCount}
                        onPageChange={(currentPage) => {
                            setCurrentPage(currentPage);
                        }}
                    />
                </VStack>
            </VStack>
        </VStack>
    );
}

export default PropertiesTeam;
