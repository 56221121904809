import { VStack, HStack, Box, Text } from "native-base";
import React from "react";
import { useForm } from "react-hook-form";

import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchTextArea from "../../components/LeadsWatchTextArea";
import { aw, ah } from "../../utils";
import LeadsWatchCard from "../../components/LeadsWatchCard";

const Subscription = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    mode: "all",
  });
  return (
    <Box marginTop={ah(30)} ml={aw(55)}>
      <LeadsWatchCard width={aw(1100)} height={ah(1000)}>
        <VStack pb={6}>
          <HStack
            width={aw(1000)}
            height={ah(50)}
            marginTop={ah(18)}
            justifyContent="space-between"
            alignItems={"baseline"}
          >
            <Text
              style={{ fontFamily: "RobotoMedium", fontSize: aw(18) }}
              color="#121233"
            >
              Subscription
            </Text>
          </HStack>

          <VStack space={ah(10)} height={ah(270)} mb={ah(20)}>
            <Text
              style={{ fontFamily: "RobotoRegular", fontSize: aw(14) }}
              color="#121233"
            >
              Enter Email Address to subscribe and seperate them with comma
            </Text>
            <LeadsWatchTextArea
              control={control}
              name={"file"}
              height={ah(200)}
              width={aw(1195)}
              placeholder={"Enter your emails here ...."}
            />
          </VStack>
          <LeadsWatchButton
            // onPress={handleChangeTextArea}
            bg={"#0000FF"}
            label={"subscribe"}
            style={{ width: aw(150) }}
            color={"#FAFBFD"}
          />
        </VStack>
      </LeadsWatchCard>
    </Box>
  );
};

export default Subscription;
