import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Center,
  Flex,
  ScrollView,
  Pressable,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";

import { aw, ah } from "../../utils";
import LeadsWatchCard from "../../components/LeadsWatchCard";
import UserDetailsCard from "./userDetailsCard";

const data = [
  {
    id: 1,
    name: "Properties",
  },

  {
    id: 3,
    name: "Campaigns",
  },

  {
    id: 5,
    name: "Signatures",
  },

  {
    id: 7,
    name: "Credits",
  },
];
const data2 = [
  {
    id: 2,
    name: "PipeLines",
  },
  {
    id: 4,
    name: "Templates",
  },
  {
    id: 6,
    name: "Subcscriptions",
  },
  {
    id: 8,
    name: "Team",
  },
];
const UserDetails = () => {
  const [expand, setExpand] = useState([]);
  const [id, setId] = useState(null);

  // const [id, setId] = useState(null);
  // useEffect(() => {
  //   if (expand.includes) {
  //     Animated.timing(animatedHeight, {
  //       toValue: 100,
  //       duration: 200,
  //       useNativeDriver: true,
  //     }).start();
  //   } else {
  //     Animated.timing(animatedHeight, {
  //       toValue: 300,
  //       duration: 200,
  //       useNativeDriver: true,
  //     }).start();
  //   }
  // }, [show]);
  const handlePress = (id) => {
    setId(id);
    if (expand.includes(id)) {
      setExpand((prev) => prev.filter((item) => item !== id));
    } else {
      setExpand((prev) => [...prev, id]);
    }
  };
  console.log(expand, id);
  return (
    <VStack width={aw(1100)} mt={ah(10)} space={ah(20)}>
      <Text fontSize={aw(14)} fontFamily="RobotoMedium" alignSelf={"center"}>
        All the Details of user will be shown here
      </Text>
      <ScrollView
        height={ah(615)}
        showsVerticalScrollIndicator={false}
        pb={ah(15)}
      >
        <Flex
          pt={ah(10)}
          display="flex"
          direction="row"
          wrap="wrap"
          alignItems={"baseline"}
          //   gap={aw(20)}
          //   alignItems={"stretch"}
          // alignItems={"space-evenly"}
          justifyContent={"space-evenly"}
        >
          <Flex
            display="flex"
            direction="column"
            gap={aw(20)}
            //   alignItems={"stretch"}
            // alignItems={"space-evenly"}
            justifyContent={"space-evenly"}
          >
            {data.map((item, index) => (
              <UserDetailsCard
                isSelected={expand.includes(item.id)}
                // backgroundColor="black"
                key={item.id}
                // h={aw(300)}
                // height={expand.includes(item.id) ? ah(100) : ah(300)}
                // height={
                //   id === item.id
                //     ? animatedHeight
                //     : expand.includes(item.id)
                //     ? ah(100)
                //     : ah(300)
                // }
                width={aw(500)}
                alignItems="flex-start"
                pb={ah(10)}
              >
                <VStack py={ah(10)} px={aw(10)}>
                  <HStack justifyContent={"space-between"} width={aw(450)}>
                    <Text fontSize={aw(14)} fontFamily="RobotoMedium">
                      {item.name}
                    </Text>
                    <Pressable onPress={() => handlePress(item.id)}>
                      {expand.includes(item.id) ? (
                        <Text fontFamily="RobotoMedium" fontSize={aw(20)}>
                          +
                        </Text>
                      ) : (
                        <Text fontFamily="RobotoMedium" fontSize={aw(20)}>
                          -
                        </Text>
                      )}
                    </Pressable>
                  </HStack>
                  <ScrollView
                    showsVerticalScrollIndicator={false}
                    maxHeight={ah(250)}
                  >
                    {data.map((val, index) => (
                      <>
                        <Text fontSize={aw(14)} fontFamily="RobotoRegular">
                          {" "}
                          {`${item.name} ${index + 1}`}
                        </Text>
                      </>
                    ))}
                  </ScrollView>
                </VStack>
              </UserDetailsCard>
            ))}
          </Flex>
          <Flex
            display="flex"
            direction="column"
            gap={aw(20)}
            //   alignItems={"stretch"}
            // alignItems={"space-evenly"}
            justifyContent={"space-evenly"}
          >
            {data2.map((item, index) => (
              <UserDetailsCard
                isSelected={expand.includes(item.id)}
                // backgroundColor="black"
                // h={aw(300)}
                // height={expand.includes(item.id) ? ah(100) : ah(300)}
                key={item.id}
                // h={aw(300)}

                width={aw(500)}
                alignItems="flex-start"
                pb={ah(10)}
              >
                <VStack py={ah(10)} px={aw(10)}>
                  <HStack justifyContent={"space-between"} width={aw(450)}>
                    <Text fontSize={aw(14)} fontFamily="RobotoMedium">
                      {item.name}
                    </Text>
                    <Pressable onPress={() => handlePress(item.id)}>
                      {expand.includes(item.id) ? (
                        <Text fontFamily="RobotoMedium" fontSize={aw(20)}>
                          +
                        </Text>
                      ) : (
                        <Text fontFamily="RobotoMedium" fontSize={aw(20)}>
                          -
                        </Text>
                      )}
                    </Pressable>
                  </HStack>
                  <ScrollView
                    showsVerticalScrollIndicator={false}
                    maxHeight={ah(250)}
                  >
                    {/* <Text>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </Text> */}

                    <VStack
                    // fontSize={aw(14)}
                    // fontFamily="RobotoRegular"
                    // numberOfLines={expand.includes(item.id) ? 2 : undefined}
                    // ellipsizeMode="tail"
                    >
                      {data.map((val, index) => (
                        <>
                          <Text fontSize={aw(14)} fontFamily="RobotoRegular">
                            {" "}
                            {`${item.name} ${index + 1}`}
                          </Text>
                        </>
                      ))}
                    </VStack>
                  </ScrollView>
                </VStack>
              </UserDetailsCard>
            ))}
          </Flex>
        </Flex>
      </ScrollView>
    </VStack>
  );
};

export default UserDetails;
