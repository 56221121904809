import React, { useState, useEffect } from "react";
import {
  Text,
  HStack,
  VStack,
  Box,
  Checkbox,
  Center,
  Image,
  Divider,
  Spinner,
  Alert,
  IconButton,
  CloseIcon,
  Pressable,
  useToast,
  Icon,
} from "native-base";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import LeadsWatchInput from "../../components/LeadsWatchInput";
import LeadsWatchEmailIcon from "../../components/svg/LeadsWatchEmailIcon";
import { useForm } from "react-hook-form";
import LeadsWatchPasswordIcon from "../../components/svg/LeadsWatchPasswordIcon";
import LeadsWatchEyeIcon from "../../components/svg/LeadsWatchEyeIcon";
import LeadsWatchEyeOffIcon from "../../components/svg/LeadsWatchEyeOffIcon";
import { aw, ah } from "../../utils";
import LeadsWatchButton from "../../components/LeadsWatchButton";
// import LeadsWatchGoogleIcon from "../../components/svg/LeadsWatchGoogleIcon";
// import LeadsWatchLeftDirection from "../../components/svg/LeadsWatchDirection";
// import LeadsWatchRightDirection from "../../components/svg/LeadsWatchRightDirection";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import { login, forgotPassword, resetPassword } from "../../store/actions/auth";
import ToastAlert from "../../components/ToastAlert";

const Login = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [rShow, setRShow] = useState(false);
  const [cShow, setCShow] = useState(false);
  const [isForgotModalOpen, setIsForgotModalOpen] = useState(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const navigate = useNavigate();
  //   const dispatch = useDispatch();
  const data = localStorage.getItem("remember");
  const credentials = JSON.parse(data);
  const [checked, setChecked] = useState(credentials ? true : false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "all",
  });
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    reset: reset1,
  } = useForm({
    mode: "all",
  });
  const {
    control: control2,
    getValues,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset: reset2,
  } = useForm({
    mode: "all",
  });
  const onSubmit = async (data) => {
    console.log(data);
    setLoading(true);
    try {
      await dispatch(login(data.email, data.password));

      reset();
    } catch (err) {
      toast.show({
        placement: "top",
        render: () => {
          return (
            <ToastAlert
              desc={err.message}
              // handleClose={() => toast.close()}
              status="error"
              title="Error"
            />
          );
        },
      });
    }
    setLoading(false);
  };
  const rPassword = async (data) => {
    setLoading1(true);
    try {
      await dispatch(forgotPassword(data["reset email"]));
      setIsForgotModalOpen(false);
      setIsResetModalOpen(true);
      reset1();
    } catch (err) {
      toast.show({
        placement: "top",
        render: () => {
          return (
            <ToastAlert
              desc={err.message}
              // handleClose={() => toast.close()}
              status="error"
              title="Error"
            />
          );
        },
      });
    }
    setLoading1(false);
  };
  const changePassword = async (data) => {
    setLoading2(true);
    console.log(data);
    try {
      await dispatch(
        resetPassword({
          resetcode: data["reset code"],
          password: data["password"],
          confirmpassword: data["cPassword"],
        })
      );
      reset2();
      setIsResetModalOpen(false);
    } catch (err) {
      toast.show({
        placement: "top",
        render: () => {
          return (
            <ToastAlert
              desc={err.message}
              // handleClose={() => toast.close()}
              status="error"
              title="Error"
            />
          );
        },
      });
    }
    setLoading2(false);
  };

  return (
    <HStack width={aw(1366)} height={ah(768)}>
      <HStack width="50%" justifyContent={"center"}>
        <VStack>
          <Text
            color="#0000FF"
            fontFamily={"RobotoMedium"}
            mt={ah(64)}
            fontSize={aw(22)}
          >
            Shadow Admin
          </Text>
          <Text
            fontFamily={"RobotoMedium"}
            mt={ah(18)}
            fontSize={aw(30)}
            color="#212121"
          >
            Welcome back
          </Text>
          <Text
            fontFamily={"RobotoRegular"}
            mt={ah(6)}
            fontSize={aw(14)}
            color="#212121"
          >
            please enter your details to login
          </Text>
          <Text
            fontFamily={"RobotoMedium"}
            fontSize={aw(15)}
            color={"red.600"}
            mt={ah(5)}
            ml={ah(4)}
          >
            {error.login && error.login}
          </Text>
          <LeadsWatchInput
            style={{ marginTop: ah(20), marginBottom: ah(17), width: aw(320) }}
            height={ah(50)}
            labelstyle={{ marginBottom: aw(6) }}
            control={control}
            name="email"
            placeholder="Enter Email"
            borderRadius={"10px"}
            borderWidth={"2px"}
            label={
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                Email
              </Text>
            }
            leftIcon={
              <LeadsWatchEmailIcon color={errors.email ? "red" : "#919191"} />
            }
            variant="rounded"
            rules={{
              required: {
                value: true,
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              },
            }}
            invalid={errors.email ? true : false}
            error={errors.email ? errors.email.message : ""}
          />

          <LeadsWatchInput
            borderRadius={"10px"}
            borderWidth={"2px"}
            labelstyle={{ marginBottom: aw(6) }}
            style={{ marginTop: aw(17), width: aw(320) }}
            height={ah(50)}
            control={control}
            name="password"
            placeholder="Enter password"
            label={
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                Password
              </Text>
            }
            rules={{
              required: {
                value: true,
              },
              // pattern: {
              //   value:
              //     /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
              // },
            }}
            invalid={errors.password ? true : false}
            error={errors.password ? errors.password.message : ""}
            type={show ? "text" : "password"}
            leftIcon={
              <LeadsWatchPasswordIcon
                color={errors.password ? "red" : "#919191"}
              />
            }
            rightIcon={
              !show ? (
                <Pressable onPress={() => setShow(!show)}>
                  <LeadsWatchEyeOffIcon />
                </Pressable>
              ) : (
                <Pressable onPress={() => setShow(!show)}>
                  <LeadsWatchEyeIcon />
                </Pressable>
              )
            }
          />

          <HStack mt={ah(50)} justifyContent="flex-end">
            <Text
              color="#212121"
              fontSize={aw(14)}
              fontFamily={"RobotoMedium"}
              onPress={() => setIsForgotModalOpen(true)}
            >
              Forgot password?
            </Text>
          </HStack>

          <HStack justifyContent="space-between">
            <LeadsWatchButton
              // onPress={() => {
              //   localStorage.setItem("auth", "20");
              //   window.location.reload();
              // }}
              onPress={handleSubmit(onSubmit)}
              color="#FAFBFD"
              isLoading={loading}
              // isLoadingText={<Spinner}
              style={{
                width: aw(320),
                height: ah(50),
                borderRadius: "10px",
                marginTop: aw(19),
              }}
              label="sign in"
              bg="#0000FF"
              borderColor="#FAFBFD"
            />
          </HStack>
          {/* <HStack justifyContent="center" mt={ah(29)}>
            <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="#494440">
              Dont have an account?{" "}
            </Text>
            <Text
              fontSize={aw(14)}
              fontFamily="RobotoMedium"
              color="#0000FF"
              borderBottomWidth="1px"
              borderBottomColor="#0000FF"
              onPress={() => navigate("/register")}
            >
              Sign up for free
            </Text>
          </HStack> */}
        </VStack>
      </HStack>
      <HStack width="50%" justifyContent="center" alignItems={"center"}>
        <Image
          width={aw(652)}
          height={ah(708)}
          justifyContent={"center"}
          alignItems="center"
          borderRadius="20px"
          source="/assets/registerBg.png"
          position="absolute"
          top={ah(30)}
          right={aw(30)}
          bottom={ah(30)}
          alt="bgImage"
        />
      </HStack>
      <LeadsWatchModal
        height={ah(400)}
        width={aw(400)}
        showModal={isForgotModalOpen}
        onClose={() => setIsForgotModalOpen(false)}
        heading={
          <HStack space={ah(10)}>
            <Text
              color={"#000000"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            >
              Reset Password
            </Text>
          </HStack>
        }
      >
        <VStack px={aw(20)}>
          <Text fontSize={aw(14)} fontFamily="RobotoRegular" color="#0000004D">
            Enter the email associated with your account and we'll send an email
            with instructions to reset your password.
          </Text>
          <Text
            fontFamily={"RobotoMedium"}
            fontSize={aw(15)}
            color={"red.600"}
            mt={ah(5)}
            ml={ah(4)}
          >
            {error.rPassword && error.rPassword}
          </Text>
          <LeadsWatchInput
            control={control1}
            name="reset email"
            borderRadius={"10px"}
            borderWidth={"2px"}
            style={{ marginTop: aw(17), width: aw(320) }}
            height={ah(50)}
            type="email"
            leftIcon={<LeadsWatchEmailIcon color={"#919191"} />}
            placeholder="email"
            rules={{
              required: {
                value: true,
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              },
            }}
            invalid={errors1["reset email"] ? true : false}
            error={errors1["reset email"] ? errors1["reset email"].message : ""}
          />
          <LeadsWatchButton
            onPress={handleSubmit1(rPassword)}
            isLoading={loading1}
            color="#FAFBFD"
            style={{
              width: aw(320),
              height: ah(50),
              borderRadius: "10px",
              marginTop: aw(19),
            }}
            label="send instructions"
            bg="#0000FF"
            borderColor="#FAFBFD"
          />
        </VStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        height={ah(500)}
        width={aw(400)}
        showModal={isResetModalOpen}
        onClose={() => setIsResetModalOpen(false)}
        heading={
          <HStack space={ah(10)}>
            <Text
              color={"#000000"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            >
              Create new password
            </Text>
          </HStack>
        }
      >
        <VStack px={aw(20)}>
          <Text fontSize={aw(14)} fontFamily="RobotoRegular" color="#0000004D">
            Protect sensitive information and unautroized access with strong and
            unique password
          </Text>
          <Text
            fontFamily={"RobotoMedium"}
            fontSize={aw(15)}
            color={"red.600"}
            mt={ah(5)}
            ml={ah(4)}
          >
            {error.changePassword && error.changePassword}
          </Text>
          <LeadsWatchInput
            control={control2}
            name="reset code"
            borderRadius={"10px"}
            borderWidth={"2px"}
            style={{ marginTop: aw(17), width: aw(320) }}
            height={ah(50)}
            type="number"
            placeholder="reset code"
            rules={{
              required: {
                value: true,
              },
            }}
            invalid={errors2["reset code"] ? true : false}
            error={errors2["reset code"] ? errors2["reset code"].message : ""}
          />

          <LeadsWatchInput
            borderRadius={"10px"}
            borderWidth={"2px"}
            labelstyle={{ marginBottom: aw(6) }}
            style={{ marginTop: aw(17), width: aw(320) }}
            height={ah(50)}
            control={control2}
            name="password"
            placeholder="New password"
            rules={{
              required: {
                value: true,
              },
            }}
            invalid={errors2.password ? true : false}
            error={errors2.password ? errors2.password.message : ""}
            type={rShow ? "text" : "password"}
            rightIcon={
              !rShow ? (
                <Pressable onPress={() => setRShow(!rShow)}>
                  <LeadsWatchEyeOffIcon />
                </Pressable>
              ) : (
                <Pressable onPress={() => setRShow(!rShow)}>
                  <LeadsWatchEyeIcon />
                </Pressable>
              )
            }
          />
          <LeadsWatchInput
            borderRadius={"10px"}
            borderWidth={"2px"}
            labelstyle={{ marginBottom: aw(6) }}
            style={{ marginTop: aw(17), width: aw(320) }}
            height={ah(50)}
            control={control2}
            name="cPassword"
            placeholder="confirm password"
            // rules={{
            //   required: {
            //     value: true,
            //     validate: (value) => value === getValues("password"),
            //   },
            // pattern: {
            //   value:
            //     /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
            // },
            // }}
            rules={{
              required: true,
              message: "Enter a valid password",
              validate: (value) => value === getValues("password"),
            }}
            invalid={errors2.cPassword ? true : false}
            error={errors2.cPassword ? errors2.cPassword.message : ""}
            type={cShow ? "text" : "password"}
            rightIcon={
              !cShow ? (
                <Pressable onPress={() => setCShow(!cShow)}>
                  <LeadsWatchEyeOffIcon />
                </Pressable>
              ) : (
                <Pressable onPress={() => setCShow(!cShow)}>
                  <LeadsWatchEyeIcon />
                </Pressable>
              )
            }
          />
          <LeadsWatchButton
            onPress={handleSubmit2(changePassword)}
            isLoading={loading2}
            color="#FAFBFD"
            style={{
              width: aw(320),
              height: ah(50),
              borderRadius: "10px",
              marginTop: aw(19),
            }}
            label="Reset password"
            bg="#0000FF"
            borderColor="#FAFBFD"
          />
        </VStack>
      </LeadsWatchModal>
    </HStack>
  );
};

export default Login;
