import * as events from "../Events";
const initialState = {
  allRegisters: null,
};

export const registersReducers = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_REGISTERS:
      return {
        ...state,
        allRegisters: action.data,
      };
    default:
      return state;
  }
};
