// uri

// import Users from "../screens/details/Users";

// export const API_URL = "http://192.168.1.110:3005/v2/referrals";
// export const API_URL = "http://localhost:2000/v2";
export const API_URL2 = "https://api.shadow.properties/v2";
export const API_URL = "https://adminapitest.shadow.properties/v2"

//auth events
export const SET_USER = "SET_USER";
export const REGISTER = "REGISTER";
export const LOGOUT = "LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_PROFILE = "GET_PROFILE";

//Details
export const GET_ALL_DETAILS = "GET_ALL_DETAILS";
export const ADD_NEW_DETAILS = "ADD_NEW_DETAILS";
export const EDIT_DETAILS = "EDIT_DETAILS";
export const GET_SINGLE_DETAILS = "GET_SINGLE_DETAILS";

//Activity
export const GET_ALL_ACTIVITY = "GET_ALL_ACTIVITY";

//Registers
export const GET_ALL_REGISTERS = "GET_ALL_REGISTERS";

//Users
export const GET_ALL_USERS = "GET_ALL_USERS";

//Teams
export const GET_ALL_TEAMS = "GET_ALL_TEAMS";

//TeamUsers
export const GET_ALL_TEAMUSERS = "GET_ALL_TEAMUSERS";

//TeamDetails
export const GET_ALL_TEAM_DETAILS = "GET_ALL_TEAM_DETAILS";
export const GET_TEAMUSERS = "GET_TEAMUSERS";
export const GET_ALL_TEAMBUYERS = "GET_ALL_TEAMBUYERS";
export const GET_ALL_PROPERTIES = "GET_ALL_PROPERTIES";
export const GET_ALL_PROPDETAILS = "GET_ALL_PROPDETAILS";
export const GET_ALL_TEAMSIGNATURES = "GET_ALL_TEAMSIGNATURES";
export const GET_ALL_TEAMTEMPLATES = "GET_ALL_TEAMTEMPLATES";
export const GET_ALL_TEAMCAMPAIGNS = "GET_ALL_TEAMCAMPAIGNS";
export const GET_ALL_TEAMLISTS = "GET_ALL_TEAMLISTS";
export const GET_ALL_TEAMLISTSDETAILS = "GET_ALL_TEAMLISTSDETAILS";
export const GET_ALL_TEAMLISTSPROPERTIES = "GET_ALL_TEAMLISTSPROPERTIES";