import * as events from "../Events";
const token = localStorage.getItem("auth");
export const allTeamDetails = (id) => {
    console.log("allTeamDetails id", id);
    return async (dispatch) => {
        try {
            const response = await fetch(
                `${events.API_URL}/admin/team/dashboard/${id}`,

                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!response.ok) {
                const resError = await response.json();
                console.log("Response Error", resError);

                if (resError && resError.error) {
                    throw new Error(resError.error);
                }
            } else {
                const resData = await response.json();
                console.log(resData.data);

                const allTeamDetails = resData.data;
                dispatch({ type: events.GET_ALL_TEAM_DETAILS, data: allTeamDetails });

                return allTeamDetails ? true : false;
            }
        } catch (err) {
            console.log(err);
            throw new Error(err.message);
        }
    };
};


