// import React, { useState, useRef, useEffect } from "react";
// import {
//     Box,
//     HStack,
//     VStack,
//     useToast,
//     Text,
//     Center,
//     Flex,
//     ScrollView,
//     Pressable,
//     Divider,
//     Badge,
//     Stack,
//     Spinner,
//     Row,
// } from "native-base";
// import { useDispatch, useSelector } from "react-redux";
// import { useParams, useNavigate } from "react-router-dom";
// import { useForm } from "react-hook-form";

// import { aw, ah, aw1, ah1 } from "../../utils";
// import LeadsWatchCard from "../../components/LeadsWatchCard";
// import LeadsWatchButton from "../../components/LeadsWatchButton";
// import LeadsWatchInput from "../../components/LeadsWatchInput";
// import ToastAlert from "../../components/ToastAlert";
// import { allTeamUsers } from "../../store/actions/teams";


// const AllUsers = () => {
//     const dispatch = useDispatch();
//     const toast = useToast();
//     const navigate = useNavigate();
//     const [isLoading, setIsLoading] = useState(true);
//     const [edit, setEdit] = useState(false);
//     const allTeamuser = useSelector((state) => state.allteamusers.allTeamUsers);
//     const { id } = useParams();
//     function chunkArray(array, chunkSize) {
//         if (!array) return [];  // <-- Guard clause

//         let chunks = [];
//         for (let i = 0; i < array.length; i += chunkSize) {
//             chunks.push(array.slice(i, i + chunkSize));
//         }
//         return chunks;
//     }

//     const {
//         control,
//         handleSubmit,
//         formState: { errors },
//         reset,
//         getValues,
//     } = useForm({
//         mode: "all",
//         defaultValues: {
//         },
//     });
//     const fetchDeatils = async () => {
//         try {
//             await dispatch(allTeamUsers(id));
//         } catch (err) {
//             toast.show({
//                 placement: "top",
//                 render: () => {
//                     return (
//                         <ToastAlert
//                             desc={err.message}
//                             // handleClose={() => toast.close()}
//                             status="error"
//                             title="error"
//                         />
//                     );
//                 },
//             });
//         }
//         setIsLoading(false);
//     }
//     useEffect(() => {
//         fetchDeatils();
//     }, []);

//     const usersList = allTeamuser ? allTeamuser || [] : [];

//     const renderUserCard = (user, index) => {
//         console.log(user)
//         return (
//             <Box
//                 key={user._id}
//                 bg="white"
//                 p={4}
//                 borderWidth={1}
//                 borderColor="gray.200"
//                 borderRadius="md"
//                 shadow={2}
//                 width="48%" // Adjust the card width as needed
//                 marginBottom={4}

//             >
//                 <Box marginBottom={2}>
//                     <Badge backgroundColor={"#1ABC9C"}>
//                         <Text fontFamily={"robortoMedium"} fontSize={aw(15)} color={"#ECF0F1"}> {index + 1}.{user.role} </Text>
//                     </Badge>
//                 </Box>

//                 <Box >
//                     <HStack marginBottom={2} >
//                         <Text fontWeight="bold" fontFamily={"robortoMedium"} fontSize={aw(15)} >FirstName : </Text>
//                         <Text fontFamily={"robortoMedium"} fontSize={aw(15)} >{user.firstname}</Text>
//                     </HStack>
//                     <HStack marginBottom={2} >
//                         <Text fontWeight="bold" fontFamily={"robortoMedium"} fontSize={aw(15)} >LastName : </Text>
//                         <Text fontFamily={"robortoMedium"} fontSize={aw(15)} >{user.lastname}</Text>
//                     </HStack>
//                     <HStack marginBottom={2}>
//                         <Text fontWeight="bold" fontFamily={"robortoMedium"} fontSize={aw(15)} >Company : </Text>
//                         <Text fontFamily={"robortoMedium"} fontSize={aw(15)}>{user.company}</Text>
//                     </HStack>
//                     <HStack marginBottom={2}>
//                         <Text fontWeight="bold" fontFamily={"robortoMedium"} fontSize={aw(15)} >Role : </Text>
//                         <Text fontFamily={"robortoMedium"} fontSize={aw(15)}>{user.role}</Text>
//                     </HStack>
//                     <HStack marginBottom={2}>
//                         <Text fontWeight="bold" fontFamily={"robortoMedium"} fontSize={aw(15)} >Email : </Text>
//                         <Text fontFamily={"robortoMedium"} fontSize={aw(15)}>{user.email}</Text>
//                     </HStack>
//                     <HStack marginBottom={2}>
//                         <Text fontWeight="bold" fontFamily={"robortoMedium"} fontSize={aw(15)} >Phone : </Text>
//                         <Text fontFamily={"robortoMedium"} fontSize={aw(15)}>{user.phone}</Text>
//                     </HStack>
//                 </Box>

//             </Box>
//         );
//     }



//     const renderNoUsersFound = () => {
//         return (
//             <Box>No users found</Box>
//         );
//     }
//     return (

//         <Stack width={aw(1000)} alignItems={"center"} p={3}>
//             <HStack width={aw(1000)} justifyContent={"flex-end"} >
//                 <LeadsWatchButton
//                     color="#FAFBFD"
//                     onPress={() => {
//                         navigate(-1)
//                     }}
//                     style={{
//                         width: aw(100),
//                         height: ah(40),
//                         borderRadius: "10px",
//                     }}
//                     label={"Back"}

//                     bg="#0000FF"
//                     borderColor="#FAFBFD"
//                 />
//             </HStack>

//             <Divider my={2} />
//             <HStack width={aw1(900)} justifyContent="space-between">
//                 {[usersList.map((user) =>
//                     <HStack  >
//                         <Pressable onPress={() => console.log(user._id)}>
//                             <Box fontWeight={"bold"}>
//                                 {user.firstname + " " + user.lastname}
//                             </Box>
//                         </Pressable>
//                     </HStack>
//                 )]}
//             </HStack>
//             <Divider my={2} />
//             {isLoading ? (
//                 <Spinner />
//             ) : usersList.length > 0 ? (
//                 <HStack flexWrap="wrap" justifyContent="space-evenly" >
//                     {usersList.map((user, index) => renderUserCard(user, index))}
//                 </HStack>
//             ) : (
//                 renderNoUsersFound()
//             )}
//         </Stack>
//     );
// };

// export default AllUsers;

import React, { useState, useEffect } from "react";
import {
    Box,
    HStack,
    useToast,
    Text,
    Pressable,
    Divider,
    Badge,
    Stack,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { ah1, aw, aw1 } from "../../utils";
import ToastAlert from "../../components/ToastAlert";
import { allTeamUsers } from "../../store/actions/teams";
import { teamUsers } from "../../store/actions/userteams";

const AllUsers = () => {

    const dispatch = useDispatch();
    const toast = useToast();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const allTeamuser = useSelector((state) => state.allteamusers.allTeamUsers);
    const teamusers = useSelector((state) => state.teamUsers.teamUsers);
    const { id } = useParams();

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        getValues,
    } = useForm({
        mode: "all",
        defaultValues: {},
    });

    const fetchUserDeatils = async (_id) => {
        // setIsLoading(true);
        try {
            await dispatch(teamUsers(_id));
        } catch (err) {
            toast.show({
                placement: "top",
                render: () => {
                    return (
                        <ToastAlert
                            desc={err.message}
                            status="error"
                            title="error"
                        />
                    );
                },
            });
        }
        setIsLoading(false);
    };

    const fetchDetails = async () => {
        try {
            await dispatch(allTeamUsers(id));
        } catch (err) {
            toast.show({
                placement: "top",
                render: () => {
                    return (
                        <ToastAlert desc={err.message} status="error" title="error" />
                    );
                },
            });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchDetails();
    }, []);
    useEffect(() => {
        if (allTeamuser) {
            fetchUserDeatils(allTeamuser[0]._id)
        }
    }, [allTeamuser])

    // const usersList = allTeamuser || [];
    const usersList = Array.isArray(allTeamuser) ? allTeamuser : [];

    const handleUserClick = (userId) => {
        console.log(userId);
        fetchUserDeatils(userId)
    };

    const UserDetailsCard = () => {
        console.log("from card", teamusers);

        const { firstname, lastname, email, address, company, os } = teamusers || {};

        const address1 = address?.address1;
        const address2 = address?.address2;
        const city = address?.city;

        const addressParts = [address1, address2, city].filter(Boolean);

        return (
            <Box
                bg="white"
                p={4}
                borderWidth={1}
                borderColor="gray.200"
                borderRadius="md"
                shadow={2}
                width="100%"
                marginBottom={4}
            >
                <Text fontWeight="bold" fontSize={aw(15)}>
                    Name : {firstname} {lastname}
                </Text>
                <Text fontWeight="bold" fontSize={aw(15)}>
                    Email : {email}
                </Text>
                <Text fontWeight="bold" fontSize={aw(15)}>
                    Address : {addressParts.join(", ")}
                </Text>
                <Text fontWeight="bold" fontSize={aw(15)}>
                    Company : {company}
                </Text>
                <Text fontWeight="bold" fontSize={aw(15)}>
                    OS : {os}
                </Text>
            </Box>
        );
    };


    return (
        <Stack width={aw(1000)} alignItems="center" p={3}>
            <HStack width={aw(1000)} justifyContent="flex-end">
                <Pressable
                    onPress={() => {
                        navigate(-1);
                    }}
                    style={{
                        width: aw(100),
                        height: aw(40),
                        borderRadius: "10px",
                    }}
                >
                    <Box bg="#0000FF" borderWidth={1} borderColor="#FAFBFD">
                        <Text color="#FAFBFD">Back</Text>
                    </Box>
                </Pressable>
            </HStack>

            <Divider my={2} />
            <HStack width={aw1(900)} justifyContent="space-between">
                {usersList.map((user) => (
                    <HStack key={user._id}>
                        <Pressable onPress={() => handleUserClick(user._id)}>
                            <Box >
                                <Text fontWeight={"bold"} fontSize={ah1(18)}>{user.firstname + " " + user.lastname}</Text></Box>
                        </Pressable>
                    </HStack>
                ))}
            </HStack>
            <Divider my={3} />

            <UserDetailsCard />
        </Stack>
    );
};

export default AllUsers;
