import * as events from "../Events";
const token = localStorage.getItem("auth");
// http://192.168.1.110:3005/v2/users/activity
export const allRegisters = (page, limit, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL2}/users/registrations?page=${page}&limit=${limit}`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            search: search,
          }),
        }
      );
      if (!response.ok) {
        const resError = await response.json();
        console.log("Response Error", resError);

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        console.log(resData.data);

        const allRegistres = resData.data;
        dispatch({ type: events.GET_ALL_REGISTERS, data: allRegistres });

        return allRegistres ? true : false;
      }
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
  };
};
