import * as events from "../Events";
const initialState = {
    allTeams: null,
    allTeamUsers: null,
    allTeamBuyers: null,
    allPropertyteam: null,
    allPropDetails: null,
    allSignatures: null,
    allTemplates: null,
    allCampaigns: null,
    allLists: null,
    allListsDetails: null,
    allListsProperties: null,
};

export const teamsReducers = (state = initialState, action) => {
    switch (action.type) {
        case events.GET_ALL_TEAMS:
            return {
                ...state,
                allTeams: action.data,
            };
        case events.GET_ALL_TEAMUSERS:
            return {
                ...state,
                allTeamUsers: action.data,
            };
        case events.GET_ALL_TEAMBUYERS:
            return {
                ...state,
                allTeamBuyers: action.data,
            };
        case events.GET_ALL_PROPERTIES:
            return {
                ...state,
                allPropertyteam: action.data,
            };
        case events.GET_ALL_PROPDETAILS:
            return {
                ...state,
                allPropDetails: action.data,
            };
        case events.GET_ALL_TEAMSIGNATURES:
            return {
                ...state,
                allSignatures: action.data
            }
        case events.GET_ALL_TEAMTEMPLATES:
            return {
                ...state,
                allTemplates: action.data
            }
        case events.GET_ALL_TEAMCAMPAIGNS:
            return {
                ...state,
                allTemplates: action.data
            }
        case events.GET_ALL_TEAMLISTS:
            return {
                ...state,
                allLists: action.data
            }
        case events.GET_ALL_TEAMLISTSDETAILS:
            return {
                ...state,
                allListsDetails: action.data
            }
        case events.GET_ALL_TEAMLISTSPROPERTIES:
            return {
                ...state,
                allListsProperties: action.data
            }
        default:
            return state;
    }
};

