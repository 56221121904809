import React, { useState, useEffect, useRef } from "react";
import { Input, FormControl } from "native-base";
import { Controller } from "react-hook-form";
import { Animated, Easing } from "react-native";
// import LeadsWatchSearchIcon from "../svg/LeadsWatchSearchIcon";

import { aw, ah } from "../utils";

const LeadsWatchSearch = React.forwardRef((props) => {
  const [focus, setFocus] = useState(false);
  const animatedWidth = useRef(new Animated.Value(120)).current;

  useEffect(() => {
    if (focus) {
      Animated.timing(animatedWidth, {
        toValue: 500,
        duration: 350,
        useNativeDriver: true,
        easing: Easing.bezier(0.2, 0.5, 0.8, 1),
      }).start();
    } else {
      Animated.timing(animatedWidth, {
        toValue: 120,
        duration: 350,
        useNativeDriver: true,
        easing: Easing.bezier(0.2, 0.5, 0.8, 1),
      }).start();
    }
  }, [focus]);
  return (
    <Animated.View
      style={{
        shadowColor: "#D7D7D7",
        shadowRadius: aw(5),
        width: animatedWidth,
        borderRadius: aw(5),
        height: ah(40),
      }}
    >
      <FormControl>
        <Controller
          control={props.control}
          name={props.name}
          style={{
            zIndex: 1,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onKeyPress={props.onKeyPress}
              color="black"
              // leftElement={<LeadsWatchSearchIcon />}
              onBlur={() => {
                setFocus(!focus);
                onBlur();
              }}
              onFocus={() => setFocus(true)}
              placeholder="search"
              borderWidth={aw(1)}
              _focus={{
                backgroundColor: "white",
              }}
              focusOutlineColor={"#0000FF"}
              borderRadius={aw(5)}
              borderColor={"#D7D7D7"}
              style={{
                fontSize: aw(14),
                fontFamily: "RobotoRegular",
                backgroundColor: "white",
                zIndex: 1,
                height: ah(40),
              }}
              value={value || ""}
              onChangeText={(value) => {
                onChange(value);
                if (props.onChangeText) {
                  props.onChangeText();
                }
              }}
              w={animatedWidth}
              InputRightElement={props.inputRightElement}
            />
          )}
        />
      </FormControl>
    </Animated.View>
  );
});

export default LeadsWatchSearch;
