import * as events from "../Events";
const token = localStorage.getItem("auth");
// http://192.168.1.110:3005/v2/users/activity
export const allUsers = (search) => {
  return async (dispatch) => {
    const url = search
      ? `https://dummyjson.com/users/search?q=${search.trim()}`
      : `https://dummyjson.com/users`;
    try {
      const response = await fetch(
        url,

        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        const resError = await response.json();
        console.log("Response Error", resError);

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        console.log(resData.data);

        const allUsers = resData;
        dispatch({ type: events.GET_ALL_USERS, data: allUsers });

        return allUsers ? true : false;
      }
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
  };
};
