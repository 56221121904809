import * as events from "../Events";
const token = localStorage.getItem("auth");
// http://192.168.1.110:3005/v2/users/activity
export const allActivities = (page, count, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `https://api.shadow.properties/v2/users/activity`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            skip:
              parseInt(page) === 1 ? 0 : (parseInt(page) - 1) * parseInt(count),
            limit: parseInt(count),
            sort: { _id: 1 },
            all: true,
            search: search,
          }),
        }
      );
      if (!response.ok) {
        const resError = await response.json();
        console.log("Response Error", resError);

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        console.log(resData.data);

        const allActivities = resData;
        dispatch({ type: events.GET_ALL_ACTIVITY, data: allActivities });

        return allActivities ? true : false;
      }
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
  };
};
