import { VStack, Text, NativeBaseProvider, HStack } from "native-base";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";

import Login from "./screens/auth/Login";

import Activity from "./screens/dashboard/Activity";
import LeadsWatchTopBar from "./components/LeadsWatchTopBar";
import LeadsWatchSideBar from "./components/LeadsWatchSideBar";
import Subscription from "./screens/subscription/Subscription";
import Registers from "./screens/registers/Registers";
import Users from "./screens/details/Users";
import UserDetails from "./screens/details/UserDetails";
import Teams from "./screens/teams/Teams";
import TeamUsers from "./screens/teams/TeamUsers";
import TeamDashBoard from "./screens/teams/TeamDashBoard";
import AllUsers from "./screens/teams/AllUsers";
import BuyerContactsPage from "./screens/teams/BuyerContacts";
import PropertiesTeam from "./screens/teams/PropertiesTeam";
import CreditsTeam from "./screens/teams/CreditsTeam";
import PropertyTeamDetails from "./screens/teams/PropertyDetails";
import { NoMatch } from "./screens/NoMatch";
import SignaturesTeam from "./screens/teams/SignaturesTeam";
import TemplatesTeam from "./screens/teams/TemplatesTeam";
import CampaignsTeam from "./screens/teams/CampaignsTeam";
import ListsTeam from "./screens/teams/ListsTeam";
import ListDetails from "./screens/teams/ListDetails";
import ListProperties from "./screens/teams/ListProperties";

const App = () => {
  const token = localStorage.getItem("auth");
  return token ? (
    <Router>
      <LeadsWatchTopBar />
      <HStack>
        <LeadsWatchSideBar />
        <Routes>
          <Route path="/" element={<Activity />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/registers" element={<Registers />} />
          <Route path="/team" element={<Teams />} />
          <Route path="/details" element={<Users />} />
          <Route path="details/userDetails/:id" element={<UserDetails />} />
          <Route path="teams/userDetails/:id" element={<TeamUsers />} />
          <Route path="teams/dashboard/:id" element={<TeamDashBoard />} />
          <Route path="teams/all/:id" element={<AllUsers />} />
          <Route path="teams/buyercontacts/:id" element={<BuyerContactsPage />} />
          <Route path="teams/properties/:id" element={<PropertiesTeam />} />
          <Route path="teams/credits/:id" element={<CreditsTeam />} />
          <Route path="teams/prop/:id" element={<PropertyTeamDetails />} />
          <Route path="*" element={<NoMatch />} />
          <Route path="teams/signatures/:id" element={<SignaturesTeam />} />
          <Route path="teams/templates/:id" element={<TemplatesTeam />} />
          <Route path="teams/campaigns/:id" element={<CampaignsTeam />} />
          <Route path="teams/lists/:id" element={<ListsTeam />} />
          <Route path="teams/lists/:id/lists/details/:id" element={<ListDetails />} />
          <Route path="teams/lists/:id/lists/details/:id/:id" element={<ListProperties />} />
        </Routes>
      </HStack>
    </Router>
  ) : (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default App;
