import * as events from "../Events";
const initialState = {
    teamUsers: null,
};

export const teamUserReducers = (state = initialState, action) => {
    switch (action.type) {
        case events.GET_TEAMUSERS:
            return {
                ...state,
                teamUsers: action.data,
            };
        default:
            return state;
    }
};

