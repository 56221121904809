import React, { useState, useEffect } from "react";
import {
    VStack,
    Spinner,
    Box,
    Text,
    HStack,
    Divider
} from "native-base";
import { aw, ah, aw1, ah1 } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { allPropDetails } from "../../store/actions/teams";
import { useParams, useNavigate } from 'react-router-dom';

import LeadsWatchButton from "../../components/LeadsWatchButton";

const PropertyTeamDetails = () => {
    const propertyDetailsData = useSelector((state) => state.propertydetails.allPropDetails);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchPropertyDetails = async () => {
            setIsLoading(true);
            try {
                await dispatch(allPropDetails(id));
            } catch (err) {
                console.log(err);
            }
            setIsLoading(false);
        }
        fetchPropertyDetails();
    }, [id, dispatch]);

    // Check if propertyDetailsData is available and not loading
    if (isLoading) {
        return (
            <VStack width="100%" p={3}>
                <Spinner color="blue.500" />
            </VStack>
        );
    }

    if (!propertyDetailsData) {
        return (
            <VStack width="100%" p={3}>
                <Text>No property details found for ID: {id}</Text>
            </VStack>
        );
    }

    // Now you can access propertyDetailsData and render the details as needed
    const {
        tid,
        acre,
        active,
        addresses,
        age,
        bath,
        bed,
        bulk,
        commercial,
        createdby,
        ev,
        floor,
        name,
        propertyaddress,
        smartsearch,
        sqft,
        team,
        yearbuilt,
        // Add more properties as needed
    } = propertyDetailsData;

    return (
        <VStack width={aw1(1100)} p={10} space={aw(3)}>
            <HStack width={aw(900)} justifyContent={"flex-end"}>
                <LeadsWatchButton
                    color="#FAFBFD"
                    onPress={() => {
                        navigate(-1)
                    }}
                    style={{
                        width: aw(100),
                        height: ah(40),
                        borderRadius: "10px",
                    }}
                    label={"Back"}

                    bg="#0000FF"
                    borderColor="#FAFBFD"
                />
            </HStack>
            <Divider />
            <Box p={aw(30)} style={{ shadowRadius: aw1(3) }}>
                <HStack width={aw1(900)} justifyContent={"space-between"} >
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"} >Property ID:</Text>
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"} > {tid}</Text>
                </HStack>
                <HStack width={aw1(900)} justifyContent={"space-between"} >
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"} >Acre: </Text>
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"} > {acre}</Text>
                </HStack>
                <HStack width={aw1(900)} justifyContent={"space-between"} >
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>Active: </Text>
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"} > {active ? 'Yes' : 'No'}</Text>
                </HStack>
                <HStack width={aw1(900)} justifyContent={"space-between"} >
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>Age: </Text>
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"} > {age}</Text>
                </HStack>
                <HStack width={aw1(900)} justifyContent={"space-between"} >
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>Bath: </Text>
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}> {bath}</Text>
                </HStack>
                <HStack width={aw1(900)} justifyContent={"space-between"} >
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>BED: </Text>
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}> {bed}</Text>
                </HStack>
                <HStack width={aw1(900)} justifyContent={"space-between"} >
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>Commercial: </Text>
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}> {commercial}</Text>
                </HStack>
                <HStack width={aw1(900)} justifyContent={"space-between"} >
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>Ev: </Text>
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}> {ev}</Text>
                </HStack>
                <HStack width={aw1(900)} justifyContent={"space-between"} >
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>Floor: </Text>
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}> {floor}</Text>
                </HStack>
                <HStack width={aw1(900)} justifyContent={"space-between"} >
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>Name: </Text>
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}> {name}</Text>
                </HStack>
                <HStack width={aw1(900)} justifyContent={"space-between"} >
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>Propertyaddress: </Text>
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}> {propertyaddress}</Text>
                </HStack>
                <HStack width={aw1(900)} justifyContent={"space-between"} >
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>smartsearch: </Text>
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}> {smartsearch ? smartsearch : "_"}</Text>
                </HStack>
                <HStack width={aw1(900)} justifyContent={"space-between"} >
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>sqft: </Text>
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}> {sqft}</Text>
                </HStack>
                <HStack width={aw1(900)} justifyContent={"space-between"} >
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>team: </Text>
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}> {team}</Text>
                </HStack>
                <HStack width={aw1(900)} justifyContent={"space-between"} >
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>yearbuilt: </Text>
                    <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}> {yearbuilt}</Text>
                </HStack>


                <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>Addresses:</Text>
                <ul>
                    {addresses.map((address, index) => (
                        <li key={index}>
                            <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>Address: {address.address1}</Text>
                            <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>City: {address.city}</Text>
                            <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>State: {address.state}</Text>
                        </li>
                    ))}
                </ul>
            </Box>
        </VStack>
    );
}

export default PropertyTeamDetails;
