import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";

import { authReducer } from "./reducer/auth";
import { activitiesReducer } from "./reducer/activity";
import { registersReducers } from "./reducer/registers";
import { usersReducers } from "./reducer/users";
import { teamsReducers } from "./reducer/teams";
import { teamUserReducers } from "./reducer/teamusers";
import { teamsDetailsReducers } from "./reducer/teamDetails";

const reducer = combineReducers({
  auth: authReducer,
  activities: activitiesReducer,
  registers: registersReducers,
  users: usersReducers,
  teams: teamsReducers,
  teamUsers: teamUserReducers,
  teamsDetails: teamsDetailsReducers,
  allteamusers: teamsReducers,
  allteambuyers: teamsReducers,
  properties: teamsReducers,
  propertydetails: teamsReducers,
});
// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsDenylist, actionsCreators and other options
});
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
export default store;
