import * as events from "../Events";
const initialState = {
    allTeamDetails: null,
};

export const teamsDetailsReducers = (state = initialState, action) => {
    switch (action.type) {
        case events.GET_ALL_TEAM_DETAILS:
            return {
                ...state,
                allTeamDetails: action.data,
            };
        default:
            return state;
    }
};

