import * as events from "../Events";
const initialState = {
  allUsers: null,
};

export const usersReducers = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.data,
      };
    default:
      return state;
  }
};
