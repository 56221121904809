import React, { useState, useEffect } from "react";
import {
  Box,
  HStack,
  VStack,
  useToast,
  Text,
  Center,
  Flex,
  ScrollView,
  Badge,
  Spinner,
  Divider,
  Pressable,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { aw, ah, aw1, ah1 } from "../../utils";
import { allTeamUsers } from "../../store/actions/teams";
import { allSignatures } from "../../store/actions/teams";
import ToastAlert from "../../components/ToastAlert";
import LeadsWatchButton from "../../components/LeadsWatchButton";

const SignaturesTeam = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const signatureDetails = useSelector((state) => state.allSignatures);
  const allTeamuser = useSelector(
    (state) => state.allteamusers.allTeamUsers
  );
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectCount, setSelectCount] = useState("10");
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null); // Store the selected user for fetching signatures
  const [signatureData, setSignatureData] = useState([]); // Store the fetched signature data

  const fetchDetails = async () => {
    try {
      await dispatch(allTeamUsers(id, selectCount, currentPage, search));
    } catch (err) {
      toast.show({
        placement: "top",
        render: () => {
          return (
            <ToastAlert desc={err.message} status="error" title="error" />
          );
        },
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDetails();
  }, [id, currentPage, selectCount, search]);

  useEffect(() => {
    const fetchSignatureData = async (userIdToFetch) => {
      try {
        const response = await dispatch(
          allSignatures(userIdToFetch, selectCount, currentPage, search)
        );
        console.log("API Response:", response); // Log the API response
        if (response && response.data && response.data.list) {
          console.log("Signature Data:", response.data.list); // Log the signature data
          setSignatureData(response.data.list);
        } else {
          console.log("No signature data found.");
          setSignatureData([]); // Set an empty array to display "No signatures found"
        }
      } catch (err) {
        toast.show({
          placement: "top",
          render: () => {
            return (
              <ToastAlert desc={err.message} status="error" title="error" />
            );
          },
        });
      }
    };

    if (selectedUser) {
      const userIdToFetch = selectedUser._id;
      fetchSignatureData(userIdToFetch);
    } else {
      fetchSignatureData(id);
    }
  }, [id, selectCount, currentPage, search, selectedUser]);

  const data = allTeamuser || [];

  const handleUserClick = async (user) => {
    // Set the selected user
    setSelectedUser(user);
  };

  return (
    <VStack height={ah1(600)} width={aw(1100)} mt={ah(10)} space={ah(20)}>
      <VStack
        // Card 1 - Display details based on URL params
        borderWidth={1}
        borderColor="gray.200"
        borderRadius="md"
        width="100%"
        p={4}
      >
        <Text fontSize={aw(14)} fontFamily="RobotoMedium">
            Owner Signatures:
        </Text>
        {/* Display signature details for the selected user or based on URL params */}
        <ScrollView>
          {signatureData.length === 0 ? (
            <Text>No signatures found.</Text>
          ) : (
            signatureData.map((signature) => (
              <Box
                key={signature.id}
                p={2}
                borderWidth={1}
                borderColor="gray.200"
                borderRadius="md"
                my={2}
              >
                {/* Display signature details here */}
                <HStack>
                  <Text fontSize={aw(12)} fontFamily="RobotoRegular" >Label : </Text>
                  <Text fontSize={aw(12)} fontFamily="RobotoRegular" >{signature.label}</Text>
                </HStack>
                <HStack>
                  <Text fontSize={aw(12)} fontFamily="RobotoRegular">Name : </Text>
                  <Text fontSize={aw(12)} fontFamily="RobotoRegular"> {signature.name}</Text>
                </HStack>
                <HStack>
                  <Text fontSize={aw(12)} fontFamily="RobotoRegular">Phone : </Text>
                  <Text fontSize={aw(12)} fontFamily="RobotoRegular">{signature.phone}</Text>
                </HStack>
                <HStack>
                  <Text fontSize={aw(12)} fontFamily="RobotoRegular">Signoff : </Text>
                  <Text fontSize={aw(12)} fontFamily="RobotoRegular">{signature.signoff}</Text>
                </HStack>
                <HStack>
                  <Text fontSize={aw(12)} fontFamily="RobotoRegular">State : </Text>
                  <Text fontSize={aw(12)} fontFamily="RobotoRegular">{signature.state}</Text>
                </HStack>
                <HStack>
                  <Text fontSize={aw(12)} fontFamily="RobotoRegular">Email : </Text>
                  <Text fontSize={aw(12)} fontFamily="RobotoRegular">{signature.email}</Text>
                </HStack>
              </Box>
            ))
          )}
        </ScrollView>
      </VStack>
      <VStack
        // Card 2 - Display users that can be clicked
        borderWidth={1}
        borderColor="gray.200"
        borderRadius="md"
        width="100%"
        p={4}
      >
        <Text fontSize={aw(14)} fontFamily="RobotoMedium">
          Users:
        </Text>
        <Divider width="100%" />
        <ScrollView>
          {data.map((user) => (
            <Pressable
              key={user.id}
              onPress={() => handleUserClick(user)}
              p={2}
              borderWidth={1}
              borderColor="gray.200"
              borderRadius="md"
              my={2}
            >
              <Text>
                {user.firstname} {user.lastname}
              </Text>
            </Pressable>
          ))}
        </ScrollView>
      </VStack>
      {selectedUser && (
        <VStack
          // Card 3 - Display signatures for the selected user
          borderWidth={1}
          borderColor="gray.200"
          borderRadius="md"
          width="100%"
          p={4}
        >
          <Text fontSize={aw(14)} fontFamily="RobotoMedium">
            Signatures for {selectedUser.firstname} {selectedUser.lastname}:
          </Text>
          <ScrollView>
            {signatureData.length === 0 ? (
              <Text>No signatures found for {selectedUser.firstname} {selectedUser.lastname}.</Text>
            ) : (
              signatureData.map((signature) => (
                <Box
                  key={signature.id}
                  p={2}
                  borderWidth={1}
                  borderColor="gray.200"
                  borderRadius="md"
                  my={2}
                >
                  {/* Display signature details here */}
                  <HStack>
                    <Text>Label : </Text>
                    <Text>{signature.label}</Text>
                  </HStack>
                  <HStack>
                    <Text>Name : </Text>
                    <Text>{signature.name}</Text>
                  </HStack>
                  <HStack>
                    <Text>Phone : </Text>
                    <Text>{signature.phone}</Text>
                  </HStack>
                  <HStack>
                    <Text>Signoff : </Text>
                    <Text>{signature.signoff}</Text>
                  </HStack>
                  <HStack>
                    <Text>State : </Text>
                    <Text>{signature.state}</Text>
                  </HStack>
                  <HStack>
                    <Text>Email : </Text>
                    <Text>{signature.email}</Text>
                  </HStack>
                </Box>
              ))
            )}
          </ScrollView>
        </VStack>
      )}
    </VStack>
  );
};

export default SignaturesTeam;
