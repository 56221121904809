import React, { useState, useEffect } from "react";
import {
    Text,
    HStack,
    VStack,
    Box,
    Center,
    Divider,
    Spinner,
    useToast,
    Pressable,
    FlatList,
    ScrollView,
    Menu,
    Card,
    Heading,
    Flex,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { TouchableOpacity } from "react-native-web";
import { aw, ah, aw1, ah1 } from "../../utils";
import ToastAlert from "../../components/ToastAlert";
import { useForm } from "react-hook-form";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSearch from "../../components/LeadsWatchSearch";
import LeadsWatchSearchIcon from "../../components/svg/LeadsWatchSearchIcon";
// import { allTeams } from "../../store/actions/teams";
import { teamUsers } from "../../store/actions/userteams";
import LeadsWatchMenu from "../../components/LeadsWatchMenu";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchInput from "../../components/LeadsWatchInput";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchCard from "../../components/LeadsWatchCard";
import UserDetailsCard from "../details/userDetailsCard";
import { allTeamDetails } from "../../store/actions/teamDasshboard";
import { allSignatures } from "../../store/actions/teams";
// import { allTeamUsers } from "../../store/actions/teams";

const TeamDashBoard = () => {
    const [disabled, setDisabled] = useState(false);
    const [loading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const toast = useToast();
    const dispatch = useDispatch();
    const teamdetails = useSelector((state) => state.teamsDetails.allTeamDetails);
    const signatureDetails = useSelector((state) => state.teams.signatureDetails);

    const signaturesTotalCount = signatureDetails ? signatureDetails.total_count : 0;

    console.log("signatureDetails:", signatureDetails);
    console.log("signaturesTotalCount:", signaturesTotalCount);

    const { id } = useParams();
    console.log(teamdetails, "id");

    useEffect(() => {
        console.log("Fetching signature details...");
        dispatch(allSignatures(id));
    }, []);

    // const {
    //     buyerContacts: { total_count: buyerContactsCount } = {},
    //     credits: { total_count: creditsCount } = {},
    //     properties: { total_count: propertiesCount } = {},
    //     users: { total_count: usersCount } = {}
    // } = teamdetails;
    const handlenavigate = (item) => {
        switch (item) {
            case 'users':
                navigate(`/teams/all/${id}`);
                console.log("users");
                break;
            case 'buyerContacts':
                navigate(`/teams/buyercontacts/${id}`);
                break;
            case 'properties':
                navigate(`/teams/properties/${id}`);
                break;
            case 'credits':
                navigate(`/teams/credits/${id}`);
                break;
            case 'signatures':
                navigate(`/teams/signatures/${id}`)
                break;
            case 'templates':
                navigate(`/teams/templates/${id}`)
                break;
            case 'campaigns':
                navigate(`/teams/campaigns/${id}`)
                break;
            case 'lists':
                navigate(`/teams/lists/${id}`)
                break;
            default:
                console.log('Unknown item:', item);
        }
    };

    const fetchDeatils = async () => {
        try {
            await dispatch(allTeamDetails(id));
        } catch (err) {
            toast.show({
                placement: "top",
                render: () => {
                    return (
                        <ToastAlert
                            desc={err.message}
                            // handleClose={() => toast.close()}
                            status="error"
                            title="error"
                        />
                    );
                },
            });
        }
        setIsLoading(false);
    }
    useEffect(() => {
        fetchDeatils();
    }, []);

    return (
        <>
            <VStack width={aw1(1100)}>
                <VStack ml={aw1(55)} space={ah1(18)} width={aw1(1100)}>
                    <HStack
                        justifyContent="space-between"
                        alignItems={"center"}
                        width={aw1(1100)}
                    >
                        <VStack justifyContent={"center"} >
                            <HStack justifyContent={"space-between"}
                                width={aw1(1100)}
                                alignItems={"center"}>
                                <VStack>
                                    <HStack width={aw1(1100)} space={aw1(8)} justifyContent={"space-between"} alignItems={"baseline"}>
                                        <Text fontSize={aw1(26)}
                                            color={"#121233"}
                                            fontFamily="RobotoMedium">Team DashBoard</Text>
                                        <LeadsWatchButton
                                            color="#FAFBFD"
                                            onPress={() => {
                                                navigate("/team")
                                            }}
                                            style={{
                                                width: aw(100),
                                                height: ah(40),
                                                borderRadius: "10px",
                                            }}
                                            label={"Back"}

                                            bg="#0000FF"
                                            borderColor="#FAFBFD"
                                        />

                                    </HStack>
                                    <Text lineHeight={ah1(34)} fontSize={ah1(14)} fontFamily={"RobotoRegular"}
                                        color={"#919191"}
                                        fontWeight={400}> the team details will be shown here</Text>
                                </VStack>


                            </HStack>
                        </VStack>
                    </HStack>
                    <Divider bg="#E6E7E9" width={aw(1100)} />
                    {teamdetails && (
                        <VStack alignItems="stretch">
                            <HStack space={ah1(18)} justifyContent="space-between" >
                                {["users", "buyerContacts", "properties", "credits"].map(item => (
                                    <Flex key={item} direction="row" wrap="wrap">
                                        <TouchableOpacity onPress={() => handlenavigate(item)}>
                                            <LeadsWatchCard height={ah1(150)} width={aw1(250)}>
                                                <VStack height={ah1(150)}>
                                                    <HStack justifyContent={"center"}>
                                                        <Text fontSize={aw1(30)}>{item}</Text>
                                                    </HStack>
                                                    <Divider width={aw1(250)} bg="#E6E7E9" />
                                                    <HStack height={ah1(90)} alignItems={"center"} justifyContent={"center"}>
                                                        <Text alignItems={"center"} fontSize={aw1(25)}>
                                                            {item === "signatures" ? signaturesTotalCount : (teamdetails[item] ? (item === "users" ? teamdetails["users"].total_count - 1 : teamdetails[item].total_count) : "_")}
                                                        </Text>
                                                    </HStack>
                                                </VStack>
                                            </LeadsWatchCard>
                                        </TouchableOpacity>
                                    </Flex>
                                ))}
                            </HStack>

                            {/* Add a new HStack for the "campaigns," "signatures," and "templates" cards */}
                            <HStack space={ah1(18)} mt={ah1(20)} justifyContent="space-between">
                                {["campaigns", "signatures", "templates", "lists"].map(item => (
                                    <Flex key={item} direction="row" wrap="wrap">
                                        <TouchableOpacity onPress={() => handlenavigate(item)}>
                                            <LeadsWatchCard height={ah1(150)} width={aw1(250)}>
                                                <VStack height={ah1(150)}>
                                                    <HStack justifyContent={"center"}>
                                                        <Text fontSize={aw1(30)}>{item}</Text>
                                                    </HStack>
                                                    <Divider width={aw1(250)} bg="#E6E7E9" />
                                                    <HStack height={ah1(90)} alignItems={"center"} justifyContent={"center"}>
                                                        <Text alignItems={"center"} fontSize={aw1(25)}>
                                                            {item === "signatures" ? signaturesTotalCount : (item === "templates" ? "0" : (teamdetails[item] ? teamdetails[item].total_count : "_"))}
                                                        </Text>
                                                    </HStack>
                                                </VStack>
                                            </LeadsWatchCard>
                                        </TouchableOpacity>
                                    </Flex>
                                ))}
                            </HStack>

                        </VStack>
                    )}

                </VStack>
            </VStack >
        </>
    )

}
export default TeamDashBoard;
