import React, { useEffect, useState } from "react";
import {
  Text,
  VStack,
  HStack,
  Flex,
  ScrollView,
  Pressable,
  useToast,
  Spinner,
} from "native-base";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { aw, ah } from "../../utils";
import LeadsWatchInput from "../../components/LeadsWatchInput";
import LeadsWatchSearchIcon from "../../components/svg/LeadsWatchSearchIcon";
import LeadsWatchCard from "../../components/LeadsWatchCard";
import { allUsers } from "../../store/actions/users";
import ToastAlert from "../../components/ToastAlert";

const Users = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.allUsers);
  const [loading, setIsLoading] = useState(true);
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const navigate = useNavigate();
  const fetchDeatils = async () => {
    // setIsLoading(true);
    try {
      await dispatch(allUsers(getValues("search")));
    } catch (err) {
      toast.show({
        placement: "top",
        render: () => {
          return (
            <ToastAlert
              desc={err.message}
              // handleClose={() => toast.close()}
              status="error"
              title="error"
            />
          );
        },
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchDeatils();
  }, []);
  return (
    <VStack width={aw(1100)}>
      <VStack ml={aw(55)} mt={ah(20)} space={aw(10)}>
        <LeadsWatchInput
          placeholder="search"
          control={control}
          name="search"
          onChangeText={(value) => fetchDeatils()}
          rightIcon={<LeadsWatchSearchIcon />}
        />
        {!loading ? (
          users && users.users.length ? (
            <ScrollView
              pt={ah(10)}
              height={ah(615)}
              showsVerticalScrollIndicator={false}
              pb={ah(10)}
            >
              <Flex
                direction="row"
                wrap="wrap"
                gap={aw(20)}
                // alignItems={"space-evenly"}
                justifyContent={"space-evenly"}
              >
                {users.users.map((item, index) => (
                  <Pressable
                    onPress={() => navigate(`/details/userDetails/${item.id}`)}
                    key={item.id}
                  >
                    <LeadsWatchCard
                      height={ah(300)}
                      width={aw(500)}
                      alignItems="flex-start"
                      pb={ah(10)}
                    >
                      <VStack py={ah(10)} px={aw(10)}>
                        <Flex direction="row">
                          <Text fontFamily="RobotoMedium" fontSize={aw(14)}>
                            firstName :
                          </Text>
                          <Text fontFamily="RobotoRegular" fontSize={aw(14)}>
                            {" "}
                            {item.firstName}
                          </Text>
                        </Flex>
                        <Flex direction="row">
                          <Text fontFamily="RobotoMedium" fontSize={aw(14)}>
                            lastName :
                          </Text>
                          <Text fontFamily="RobotoRegular" fontSize={aw(14)}>
                            {" "}
                            {item.lastName}
                          </Text>
                        </Flex>
                        <Flex direction="row">
                          <Text fontFamily="RobotoMedium" fontSize={aw(14)}>
                            Email :
                          </Text>
                          <Text fontFamily="RobotoRegular" fontSize={aw(14)}>
                            {" "}
                            {item.email}
                          </Text>
                        </Flex>
                        <Flex direction="row">
                          <Text fontFamily="RobotoMedium" fontSize={aw(14)}>
                            Phone :
                          </Text>
                          <Text fontFamily="RobotoRegular" fontSize={aw(14)}>
                            {" "}
                            {item.phone}
                          </Text>
                        </Flex>
                      </VStack>
                    </LeadsWatchCard>
                  </Pressable>
                ))}
              </Flex>
            </ScrollView>
          ) : (
            <Text>No Users</Text>
          )
        ) : (
          <HStack
            justifyContent="center"
            alignItems={"center"}
            height={ah(550)}
            // style={{
            //   border: "1px solid #D7D7D7",
            //   borderTopWidth: 0,
            //   borderBottomLeftRadius: aw(10),
            //   borderBottomRightRadius: aw(10),
            // }}
          >
            <Spinner size="lg" color="#2230F8" />
          </HStack>
        )}
      </VStack>
    </VStack>
  );
};

export default Users;
