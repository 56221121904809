import { Box, Pressable, Text } from "native-base";
import React, { useRef, useEffect } from "react";

import { Animated } from "react-native";

// import { aw, ah } from "../utils";

const UserDetailsCard = (props) => {
  const animatedHeight = useRef(
    new Animated.Value(props.isSelected ? 90 : 300)
  ).current;
  useEffect(() => {
    if (props.isSelected) {
      Animated.timing(animatedHeight, {
        toValue: 90,
        duration: 300,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(animatedHeight, {
        toValue: 300,
        duration: 300,
        useNativeDriver: true,
      }).start();
    }
  }, [props.isSelected]);
  return (
    // <Pressable>
    <Animated.View
      //   key={item.id}
      style={{
        maxHeight: animatedHeight,
        overflow: "hidden",
        shadowColor: props.shadow ? null : props.shadowColor || "#0000001A",
        shadowOffset: props.shadow
          ? null
          : props.shadowOffset || { width: "0px", height: "2px" },
        shadowRadius: props.shadow ? null : props.shadowRadius || "10px",
        borderRadius: props.borderRadius || "14px",
      }}
    >
      <Box
        // overflow={"hidden"}
        ml={0.5}
        maxH={animatedHeight}
        height={props.h}
        pb={props.pb}
        style={{
          borderRadius: props.borderRadius,
          borderWidth: props.borderWidth,
          borderColor: props.borderColor,
          alignItems: props.alignItems || "center",
          width: props.width || 141,
          backgroundColor: props.backgroundColor || "#FFFFFF",
          //   shadowColor: props.shadow ? null : props.shadowColor || "#0000001A",
          //   shadowOffset: props.shadow
          //     ? null
          //     : props.shadowOffset || { width: "0px", height: "2px" },
          //   shadowRadius: props.shadow ? null : props.shadowRadius || "10px",
          borderRadius: props.borderRadius || "14px",
        }}
      >
        {props.children}
      </Box>
    </Animated.View>
  );
};

export default UserDetailsCard;
