import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    HStack,
    VStack,
    useToast,
    Text,
    Center,
    Flex,
    ScrollView,
    Badge,
    Spinner,
    Divider,
    Pressable,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { aw, ah, aw1, ah1 } from "../../utils";
import { allTeamDetails } from "../../store/actions/teamDasshboard";
import ToastAlert from "../../components/ToastAlert";
import LeadsWatchButton from "../../components/LeadsWatchButton";


const CreditsTeam = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const toast = useToast();
    const navigate = useNavigate();
    const teamdetails = useSelector((state) => state.teamsDetails.allTeamDetails);

    const [isLoading, setIsLoading] = useState(true);

    const fetchDeatils = async () => {
        try {
            await dispatch(allTeamDetails(id));
        } catch (err) {
            toast.show({
                placement: "top",
                render: () => {
                    return (
                        <ToastAlert
                            desc={err.message}
                            status="error"
                            title="error"
                        />
                    );
                },
            });
        }
        setIsLoading(false);
    }
    useEffect(() => {
        fetchDeatils();
    }, []);
    const data = teamdetails ? teamdetails.credits?.list || [] : [];
    const renderUserCard = (user) => {
        console.log(user, "1234567")
        return (
            <Box
                key={user._id}
                bg="white"
                p={4}
                borderWidth={1}
                borderColor="gray.200"
                borderRadius="md"
                shadow={2}
                width="50%" // Adjust the card width as needed
                marginBottom={4}

            >
                <Box marginBottom={2}>
                    <Badge backgroundColor={"#1ABC9C"}>
                        <Text fontFamily={"robotoMedium"} fontSize={aw(15)} color={"#ECF0F1"}>  {user.role} </Text>
                    </Badge>
                </Box>

                <Box >
                    <HStack marginBottom={2} >
                        <Text fontWeight="bold" fontFamily={"robotoMedium"} fontSize={aw(15)} >_id : </Text>
                        <Text fontFamily={"robotoMedium"} fontSize={aw(15)} >{user._id}</Text>
                    </HStack>
                    <HStack marginBottom={2} >
                        <Text fontWeight="bold" fontFamily={"robotoMedium"} fontSize={aw(15)} >Decsciption : </Text>
                        <Text fontFamily={"robotoMedium"} fontSize={aw(15)} >{user.desc}</Text>
                    </HStack>
                    <HStack marginBottom={2}>
                        <Text fontWeight="bold" fontFamily={"robotoMedium"} fontSize={aw(15)} >Credits: </Text>
                        <Text fontFamily={"robotoMedium"} fontSize={aw(15)}>{user.credits}</Text>
                    </HStack>
                    <HStack marginBottom={2}>
                        <Text fontWeight="bold" fontFamily={"robotoMedium"} fontSize={aw(15)} >Total Team Credits  : </Text>
                        <Text fontFamily={"robotoMedium"} fontSize={aw(15)}>{user && user.sum ? user.sum : "_"}</Text>
                    </HStack>
                </Box>
            </Box>
        );
    }
    const renderNoUsersFound = () => {
        return (
            <Box>No users found</Box>
        );
    }
    return (
        <VStack height={ah1(600)} width={aw(1100)} mt={ah(10)} space={ah(20)}>
            <Text fontSize={aw(14)} fontFamily="RobotoMedium" alignSelf={"center"}>
                All the team's credits will be shown here
            </Text>
            <VStack width={aw(1100)} alignItems={"center"} p={3}>
                <HStack width={aw(1000)} justifyContent={"flex-end"}>
                    <LeadsWatchButton
                        color="#FAFBFD"
                        onPress={() => {
                            navigate(-1)
                        }}
                        style={{
                            width: aw(100),
                            height: ah(40),
                            borderRadius: "10px",
                        }}
                        label={"Back"}

                        bg="#0000FF"
                        borderColor="#FAFBFD"
                    />
                </HStack>
                <Divider my={2} />
                <ScrollView height={ah1(500)} width={aw1(1100)} showsVerticalScrollIndicator={false}>
                    {isLoading ? (
                        <Spinner />
                    ) : data.length > 0 ? (
                        <HStack flexWrap="wrap" justifyContent="space-evenly" >
                            {data.map((user) => renderUserCard(user))}
                        </HStack>
                    ) : (
                        renderNoUsersFound()
                    )}
                </ScrollView>
            </VStack>
        </VStack >
    );
};

export default CreditsTeam;
