import React, { useRef, useState, useEffect } from "react";
import {
    Box,
    HStack,
    VStack,
    useToast,
    Text,
    Center,
    Flex,
    ScrollView,
    Badge,
    Spinner,
    Divider,
    Pressable,
    Card,
    Stack,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import ToastAlert from "../../components/ToastAlert";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import { aw, ah, aw1, ah1 } from "../../utils";
import { allListsProperties } from "../../store/actions/teams";




const ListProperties = () => {
    const navigate = useNavigate();
    const [selectedCard, setSelectedCard] = useState(null);
    const [listdetailsData, setListDetailsData] = useState([]);
    const { id } = useParams();
    const dispatch = useDispatch();
    const toast = useToast();

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };



    const handleCardSelect = (cardName) => {
        setSelectedCard(cardName);
    };

    const fetchListPropertyDetails = async () => {
        try {
            const response = await dispatch(
                allListsProperties(id)
            );
            console.log("lists Details Response:", response);
            if (response && response.data) {
                console.log("lists Details  Data:", response.data);
                setListDetailsData(response.data);
            } else {
                console.log("No lists Details  data found.");
                setListDetailsData([]);
            }
        } catch (err) {
            console.error("Error fetching lists Details  data:", err);
            toast.show({
                placement: "top",
                render: () => {
                    return (
                        <ToastAlert desc={err.message} status="error" title="error" />
                    );
                },
            });
        }
    };

    useEffect(() => {
        fetchListPropertyDetails();
    }, [id]);


    return (
        <VStack height={ah1(600)} width={aw(1100)} mt={ah(10)} space={ah(10)}>
 {listdetailsData.length === 0 ? (
                    <Text>Properties Not added</Text>
                ) : (
                    <>
            <HStack width={aw(1100)} ml={aw(25)} mt={ah(10)} justifyContent={"space-between"}>
                <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>Property Details</Text>
                <LeadsWatchButton
                    color="#FAFBFD"
                    onPress={() => {
                        navigate(-1)
                    }}
                    style={{
                        width: aw(100),
                        height: ah(40),
                        borderRadius: "10px",
                    }}
                    label={"Back"}
                    bg="#0000FF"
                    borderColor="#FAFBFD"
                />
            </HStack>
            <Divider ml={aw(25)} />
            <HStack ml={aw(25)} justifyContent="space-evenly">
                <Pressable onPress={() => handleCardSelect('info')}>
                    <Text color="black" fontSize={aw(14)} fontFamily="RobotoMedium">
                        Info
                    </Text>
                </Pressable>
                <Pressable onPress={() => handleCardSelect('property')}>
                    <Text color="black" fontSize={aw(14)} fontFamily="RobotoMedium">
                        Property
                    </Text>
                </Pressable>
                <Pressable onPress={() => handleCardSelect('campaign')}>
                    <Text color="black" fontSize={aw(14)} fontFamily="RobotoMedium">
                        Campaign
                    </Text>
                </Pressable>
            </HStack>
            <Divider ml={aw(25)} />
            <Stack ml={aw(45)}>
                {selectedCard === 'info' && (

                    <Card
                    >
                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">property information </Text>
                        <Divider my={ah(10)} />
                        <VStack space={ah(15)} >
                            <VStack width={aw(500)} space={ah(15)}>
                                <HStack justifyContent={"space-between"}>
                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">Owner Name : </Text>
                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">{listdetailsData.name}</Text>
                                </HStack>
                                <HStack justifyContent={"space-between"}>
                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">Gender : </Text>
                                    {listdetailsData.gender === null || listdetailsData.gender === undefined ? (
                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">N/A</Text>
                                    ) : (
                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">{listdetailsData.gender}</Text>
                                    )}

                                </HStack>
                                <HStack justifyContent={"space-between"}>
                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">Age : </Text>
                                    {listdetailsData.age === null || listdetailsData.age === undefined ? (
                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">N/A</Text>
                                    ) : (
                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">{listdetailsData.age}</Text>
                                    )}

                                </HStack>
                                <HStack justifyContent={"space-between"}>
                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">Age : </Text>
                                    {listdetailsData.age === null || listdetailsData.age === undefined ? (
                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">N/A</Text>
                                    ) : (
                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">{listdetailsData.age}</Text>
                                    )}

                                </HStack>
                                <HStack justifyContent={"space-between"}>
                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">Notes : </Text>
                                    {listdetailsData.notes.name === null || listdetailsData.notes.name === undefined ? (
                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">--</Text>
                                    ) : (
                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">{listdetailsData.notes[0].name}</Text>
                                    )}

                                </HStack>
                                <HStack justifyContent={"space-between"}>
                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">Phone Numbers : </Text>
                                    {listdetailsData.phones.phone === null || listdetailsData.phones.phone === undefined ? (
                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">--</Text>
                                    ) : (
                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">{listdetailsData.phones[0].phone}</Text>
                                    )}

                                </HStack>
                                <HStack justifyContent={"space-between"}>
                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">Email Addresses : </Text>
                                    {listdetailsData.emails.email === null || listdetailsData.emails.email === undefined ? (
                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">--</Text>
                                    ) : (
                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">{listdetailsData.emails[0].email}</Text>
                                    )}

                                </HStack>
                                <HStack justifyContent={"space-between"}>
                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">Tags : </Text>
                                    {listdetailsData.tags.name === null || listdetailsData.tags.name === undefined ? (
                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">No Tags</Text>
                                    ) : (
                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">{listdetailsData.tags[0].tag}</Text>
                                    )}
                                </HStack>
                                <HStack justifyContent={"space-between"}>
                                    <Text fontSize={aw(12)} fontFamily="RobotoRegular">Property Photos : </Text>
                                    {listdetailsData.pics && listdetailsData.pics.length > 0 ? (
                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">{listdetailsData.pics[0].path}</Text>
                                    ) : (
                                        <Text fontSize={aw(12)} fontFamily="RobotoRegular">No Property Photos</Text>
                                    )}
                                </HStack>

                            </VStack>
                        </VStack>
                    </Card>


                )}

                {selectedCard === 'property' && (
                     <VStack>
                     <Card>
                     <Pressable onPress={toggleExpansion}>
                       <HStack justifyContent="space-between">
                         <Text fontSize={aw(12)} fontFamily="RobotoRegular" >
                          Parcel
                         </Text>
                         <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                           {isExpanded ? '▲' : '▲'}
                         </Text>
                       </HStack>
                     </Pressable>
             
                     {isExpanded && (
                       <VStack width={aw(450)} mt={ah(15)} space={ah(25)} >
                       <HStack space={aw(15)} justifyContent={"space-between"}>
                       <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                       Address 
                       </Text>
                       <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                       {listdetailsData.owneraddress}
                       </Text>
                       </HStack>
                       <HStack space={aw(15)} justifyContent={"space-between"}>
                       <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                       Area Lot(Acres) 
                       </Text>
                       <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                       {listdetailsData.acre}
                       </Text>
                       </HStack>
                       <HStack space={aw(15)} justifyContent={"space-between"}>
                       <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                       County
                       </Text>
                       <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                       need to set
                       </Text>
                       </HStack>
                       <HStack space={aw(15)} justifyContent={"space-between"}>
                       <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                       Lot Number
                       </Text>
                       {listdetailsData.lot === null || listdetailsData.lot === undefined ? (
                                       <Text fontSize={aw(12)} fontFamily="RobotoRegular">N/A</Text>
                                   ) : (
                                       <Text fontSize={aw(12)} fontFamily="RobotoRegular">{listdetailsData.lot}</Text>
                                   )}
                       </HStack>
                       <HStack space={aw(15)} justifyContent={"space-between"}>
                       <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                       Zoning Destination
                       </Text>
                       {listdetailsData.lot === null || listdetailsData.lot === undefined ? (
                                       <Text fontSize={aw(12)} fontFamily="RobotoRegular">N/A</Text>
                                   ) : (
                                       <Text fontSize={aw(12)} fontFamily="RobotoRegular">{listdetailsData.lot}</Text>
                                   )}
                       </HStack>
                       </VStack>
                     )}
                   </Card>
                   <Card>
                     <Pressable onPress={toggleExpansion}>
                       <HStack justifyContent="space-between">
                         <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                           Property  #{listdetailsData.tid}
                         </Text>
                         <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                           {isExpanded ? '▲' : '▲'}
                         </Text>
                       </HStack>
                     </Pressable>
                     {isExpanded && (
                       <Text fontSize={aw(12)} fontFamily="RobotoRegular">
                       DATA
                       </Text>
                     )}
                   </Card>
                   </VStack>
                )}

                {selectedCard === 'campaign' && (
                   <Card
                   >
                       <Text fontSize={aw(12)} fontFamily="RobotoRegular">Campaign information </Text>
                       <Divider my={ah(10)} />
                       <VStack space={ah(15)} >
                           <VStack width={aw(500)} space={ah(15)}>
                               <HStack justifyContent={"space-between"}>
                                   <Text fontSize={aw(12)} fontFamily="RobotoRegular">Campaign config: </Text>
                                   <Text fontSize={aw(12)} fontFamily="RobotoRegular">{listdetailsData.name}</Text>
                               </HStack>
                               <HStack justifyContent={"space-between"}>
                                   <Text fontSize={aw(12)} fontFamily="RobotoRegular">Postal Address : </Text>
                                   {listdetailsData.owneraddress === null || listdetailsData.owneraddress === undefined ? (
                                       <Text fontSize={aw(12)} fontFamily="RobotoRegular">N/A</Text>
                                   ) : (
                                       <Text fontSize={aw(12)} fontFamily="RobotoRegular">{listdetailsData.owneraddress}</Text>
                                   )}
                               </HStack>
                               <HStack justifyContent={"space-between"}>
                                   <Text fontSize={aw(12)} fontFamily="RobotoRegular">Mail Activity : </Text>
                                   {listdetailsData.age === null || listdetailsData.age === undefined ? (
                                       <Text fontSize={aw(12)} fontFamily="RobotoRegular">N/A</Text>
                                   ) : (
                                       <Text  fontSize={aw(12)} fontFamily="RobotoRegular">{listdetailsData.age}</Text>
                                   )}
                               </HStack>
                           </VStack>
                       </VStack>
                   </Card>
                )}
            </Stack>
            </>
                )}
        </VStack>
    );
};

export default ListProperties;
