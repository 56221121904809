import React, { useState, useEffect } from "react";
import { Text, Box, FormControl, TextArea } from "native-base";
import { Controller } from "react-hook-form";
import { aw, ah } from "../utils";

const LeadsWatchTextArea = React.forwardRef((props, ref) => {
  return (
    <FormControl
      // position="relative"
      isRequired={props.isRequired}
      isInvalid={props.invalid}
      isReadOnly={props.isReadOnly || false}
      style={{
        height: ah(129),
        ...props.style,
      }}
    >
      {props.label && (
        <FormControl.Label
          style={{
            fontSize: aw(13),
            fontFamily: "RobotoRegular",
            color: props.invalid ? "red.500" : "rgba(102, 102, 102, 0.7)",
            height: ah(19),
            ...props.labelstyle,
          }}
        >
          {props.label}
        </FormControl.Label>
      )}

      <Controller
        control={props.control}
        rules={props.rules}
        name={props.name}
        style={{
          zIndex: 1,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextArea
            color="#212121"
            h={props.height || ah(120)}
            // w={props.width}
            mt={props.mt}
            placeholder={props.placeholder}
            tabIndex={props.tabIndex}
            isReadOnly={props.isReadOnly || false}
            ref={ref}
            isInvalid={props.invalid}
            autoComplete="off"
            autoFocus={props.autoFocus || false}
            onSubmitEditing={props.onSubmitEditing}
            onKeyPress={props.onKeyPress}
            borderWidth={aw(1)}
            borderColor="#D1D5DB"
            value={value || ""}
            onChangeText={(value) => {
              onChange(value);
              if (props.onChangeText) {
                props.onChangeText();
              }
            }}
            onBlur={() => {
              onBlur();
            }}
            _hover={{
              borderColor: "#D1D5DB",
            }}
            invalidOutlineColor={"red.300"}
            focusOutlineColor={"#0000FF"}
            _invalid={{
              borderColor: "red.300",
              borderWidth: "1",
            }}
            _light={{
              borderWidth: "1",
            }}
            _dark={{
              borderWidth: "1",
            }}
            _web={{
              borderWidth: "1",
            }}
            style={{
              fontSize: aw(14),
              fontFamily: "RobotoRegular",
              backgroundColor: "white",
              zIndex: 1,
            }}
          />
        )}
      />

      <FormControl.ErrorMessage style={{ marginTop: ah(-3) }}>
        {props.error}
      </FormControl.ErrorMessage>
      <FormControl.HelperText style={{ marginTop: ah(-3) }}>
        {props.help}
      </FormControl.HelperText>
    </FormControl>
  );
});

export default LeadsWatchTextArea;
